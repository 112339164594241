import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Grid,
  MenuItem,
  //Select,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText
} from '@material-ui/core';
import { TextField, PasswordTextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';
import AdminModel from 'model/AdminModel';

const AdminAddPage = () => {
  const history = useHistory();

  const [adminUsername, setAdminUsername] = useState('')
  //const [adminEmail, setAdminEmail] = useState('')
  const [adminPassword, setAdminPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [allowSubmit, setAllowSubmit] = useState(false)

  const checkSubmit = async e => {
    if(adminUsername != '' && adminPassword != '' && confirmPassword != '' && adminPassword == confirmPassword) {
        setAllowSubmit(true)
    } else {
        setAllowSubmit(false)
    }
  };

  const handleSubmit = async e => {
    try {

      let body = {
        user_name: adminUsername,
        password: adminPassword,
      };

      console.log('submitting', body);

      /* setAdminUsername('');
      setAdminEmail('');
      setAdminPassword('');
      setConfirmPassword(''); */

      let result = await AdminModel.create(body);

      swal({
        title: 'Success',
        text: "Admin '" + result.user_name + "' has been added successfully",
        icon: 'success'
      }).then(() => {
        history.push("/admin/" + result.id)
      });
    } catch (e) {
      console.log(e);

      swal({
        title: 'Error',
        text: e.error_message ? e.error_message : 'An Error Occurred',
        icon: 'error',
        customClass: {
          container: 'swal-z'
        }
      });
    }
  };

  useEffect(() => {
    checkSubmit()
  }, [adminUsername, adminPassword, confirmPassword]);

  return (
    <>
      <Card>
        <CardHeader title="Admin details">
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={adminUsername}
                  onChange={e => setAdminUsername(e.target.value)}
                  label="Username *"
                  input={<OutlinedInput label="Tag" />}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  value={adminEmail}
                  onChange={e => setAdminEmail(e.target.value)}
                  label="Email *"
                  input={<OutlinedInput label="Tag" />}
                />
              </Grid> */}
              <Grid item xs={12}>
                <PasswordTextField
                  value={adminPassword}
                  onChange={e => setAdminPassword(e.target.value)}
                  label="Password *"
                  input={<OutlinedInput label="Tag" />}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextField
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  label="Confirm Password *"
                  input={<OutlinedInput label="Tag" />}
                  helperText={adminPassword != confirmPassword ? 'Password not matching' : ''}
                  error={adminPassword != confirmPassword ? true : false}
                />
              </Grid>
            </Grid>
          </form>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button disabled={!allowSubmit} color={'primary'} variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminAddPage;
