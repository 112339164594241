import APIRequest from "../utils/APIRequest";

export default class AdminModel {

    static getAllAdmins = async () => {
        return await APIRequest.set('v1/admins', 'GET');
    };

    static getAdminById = async (id) => {
        return await APIRequest.set('v1/admin/'+id, 'GET');
    };

    static edit = async (id, values) => {
        return await APIRequest.set('v1/admin/'+id, 'PUT', values);
    };

    static create = async (values) => {
        return await APIRequest.set('v1/admin', 'POST', values)
    }

}
