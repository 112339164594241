import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal, Radio } from '@material-ui/core';
import { Row, Col, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';

export default function DiagnoseSymptomSelectionModal({ modalData, onClose, onSubmit }) {
  const [tmpSymptom, setTmpSymptom] = useState([]);

  useEffect(() => {
    //console.log('MDD', modalData);
    if (modalData?.detail) {
      //console.log('MDD', modalData.detail);

      let tempSymptom = []

      for(let i = 0; i < modalData.symptoms_detail.length; i++) {
        let name = modalData.symptoms_detail[i].detail_name
        let a = modalData.detail.find(obj => obj.name == name)
        //console.log("berhitung", a)
        tempSymptom.push(a?.options)
      }
      
      setTmpSymptom(tempSymptom)

      /* setTmpSymptom(
        modalData.detail.map(obj => {
          return obj.options?.map(obj2 => {
            return {
              ...obj2
            };
          });
        })
      ); */
    }
  }, [modalData]);

  const handleClose = () => {
    setTmpSymptom([]);
    onClose();
  };

  const handleSubmit = tmpSymptom => {
    //console.log('UPDATERESULTINSIDE', tmpSymptom);
    let pass = false

    for(let i = 0; i < tmpSymptom.length; i++) {
      if(tmpSymptom[i] != null && tmpSymptom[i].length > 0) {
        pass = true;
        break;
      }
    }

    if (tmpSymptom.length == 0 || pass == false) {
      swal({
        title: 'Empty detail',
        text: 'You must fill at least 1 detail',
        icon: 'error'
      });
    } else {
      onSubmit(tmpSymptom);
      handleClose();
    }
  };

  //console.log('MDD SELECTED', tmpSymptom);

  return (
    <Modal open={modalData} onClose={() => handleClose()} style={{}}>
      <div
        style={{
          position: 'absolute',
          width: '27.5rem',
          backgroundColor: 'white',
          //borderWidth: 0,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          //borderColor: 'transparent',
          borderRadius: '0.75rem',
          padding: '1.25rem'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.5rem' }}>
          <h4>
            <b>{modalData?.name}</b>
          </h4>
          <Button color={'secondary'} variant="contained" style={{ padding: 0 }} onClick={() => handleClose()}>
            X
          </Button>
        </div>

        {modalData?.symptoms_detail?.map((el, idx) => {
          /* let symptom = el.symptom_detail_options.map(sy => ({
          symptom_id: el.id,
          label: sy.options,
          symptom_detail_option_id: sy.id
        })); */
          //const [tmpSelected, setTmpSelected] = useState([])
          //console.log(el, tmpSymptom, el?.options, 'WK');
          if (!el) {
            return;
          }
          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>{el.detail_name}</b>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* {tmpSymptom && JSON.stringify(tmpSymptom[idx])} */}
                {/* <MultipleSelect
                  label={''}
                  options={el.options}
                  selected={tmpSymptom[idx] ?? []}
                  setSelected={value => {
                    //console.log('tmp', value);

                    //console.log('tmp state', tmpSymptom);
                    let temp = [...tmpSymptom];
                    temp[idx] = value;
                    setTmpSymptom(temp);
                    //console.log('MDD tmp', temp);
                  }}
                /> */}

                

                {el?.options?.map(e => (
                    el.type == 'checkbox' ? 
                        <Form>
                            <div
                                onClick={() => {
                                    // console.log("PDP5", temp)

                                    ////console.log("ini", tmpSymptom[idx])
                                    let temp = [...tmpSymptom];

                                    if(temp[idx]?.[0] != null || temp[idx]?.length > 0) {                                    
                                        let tempDalam = [...temp[idx]]

                                        let obj = false

                                        tempDalam?.find((o, i) => {
                                           ////console.log("masuk pak eko", i, o.value, e.value)
                                            if (o.value == e.value) {
                                                ////console.log("cekcek")
                                                tempDalam.splice(i, 1)
                                                
                                                obj = true;
                                                return true;
                                            }
                                        });

                                        ////console.log("intip", tempDalam, obj)
                                        if(obj == false) {
                                            let data = {
                                              detail_name: idx,
                                              ...e
                                            }
                                            tempDalam.push(data)
                                        }

                                        temp[idx] = tempDalam
                                    } else {
                                        temp[idx] = [e]
                                    }

                                    console.log("PDP5", temp)
                                                                        
                                    setTmpSymptom(temp);
                                    //console.log('MDD tmp', tmpSymptom);
                                }}
                                key={`inline-${e.value}`} className="mb-3" style={{
                                borderStyle: "solid",
                                borderRadius: 10,
                                borderWidth: "thin",
                                //borderColor: eatings.includes(e.id) ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                                borderColor: "#bfbaba",
                                width: "100%",
                                padding: 7,
                                //backgroundColor: eatings.includes(e.id) ? "rgba(0, 155, 255, 0.2)" : "transparent",
                                cursor: "pointer"
                                //marginTop: "-1.5vh"
                                }}>
                                <Form.Check
                                    //custom
                                    inline
                                    label={e.label}
                                    name={e.value + '-name'}
                                    type="checkbox"
                                    id={e.value + '-id'}
                                    checked={tmpSymptom[idx]?.find(obj => obj.value == e.value) ? true : false}
                                    
                                />
                            </div>

                        </Form>
                    
                    :
                   
                        <div
                            onClick={()=>{
                                //console.log("ini", tmpSymptom[idx])
                               
                                let temp = [...tmpSymptom];
                                if(temp[idx]?.[0]?.value == e.value) {
                                  temp[idx] = null
                                } else {
                                  temp[idx] = [e]
                                }
                                
                                setTmpSymptom(temp);
                                //console.log('MDD tmp', tmpSymptom);

                                //setSubmitData({ ...submitData, [el]: e.id });
                            }}
                            style={{
                                borderStyle: "solid",
                                borderRadius: 10,
                                borderWidth: "thin",
                                //borderColor: submitData[el] == e.id ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                                borderColor: "#bfbaba",
                                width: "100%",
                                marginBottom: 5,
                                //backgroundColor: submitData[el] == e.id ? "rgba(0, 155, 255, 0.2)" : "transparent",
                                cursor : "pointer"
                                //marginTop: "-1.5vh"
                            }}
                            >
                            <Radio
                                checked={tmpSymptom[idx]?.[0]?.value == e.value}
                                // onChange={event => handleChange(event, el)}
                                value={e.value}
                                color="primary"
                                //name={el}
                                inputProps={{ 'aria-label': e.value }}
                            />
                            {e.label}
                        </div>
                    
                ))}
              </Grid>
            </Grid>
          );
        })}
        <br />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            color={'primary'}
            variant="contained"
            onClick={() => {
              handleSubmit(tmpSymptom);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

