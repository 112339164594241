/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive('/report', false)}`}>
          <NavLink className="menu-link" to="/report">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Report</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/incoming-appointment', false)}`}>
          <NavLink className="menu-link" to="/incoming-appointment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">New Appointments</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/appointment', false)}`}>
          <NavLink className="menu-link" to="/appointment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Appointment</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/doctor', false)}`}>
          <NavLink className="menu-link" to="/doctor">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Doctor</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/admin', false)}`}>
          <NavLink className="menu-link" to="/admin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Admin</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/medical-records', false)}`}>
          <NavLink className="menu-link" to="/v2/medical-records">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Medical Records</span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">Clinic & Operation Table</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive('/clinic', false)}`}>
          <NavLink className="menu-link" to="/clinic">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Clinic</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/operation', false)}`}>
          <NavLink className="menu-link" to="/operation">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Operation Table
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        <li className="menu-section ">
          <h4 className="menu-text">Customer & Patient</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive('/customer', false)}`}>
          <NavLink className="menu-link" to="/customer">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Customer</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/patient', false)}`}>
          <NavLink className="menu-link" to="/patient">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Patient</span>
          </NavLink>
        </li>
        {/* <li className={`menu-item ${getMenuItemActive('/participant', false)}`}>
          <NavLink className="menu-link" to="/participant">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Participant</span>
          </NavLink>
        </li> */}

        <li className="menu-section ">
          <h4 className="menu-text">Nametag</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive('/nametag', false)}`}>
          <NavLink className="menu-link" to="/nametag">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Nametag</span>
          </NavLink>
        </li>


        {/* <li className="menu-section ">
          <h4 className="menu-text">Diagnosis</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive('/diagnose', false)}`}>
          <NavLink className="menu-link" to="/diagnose/1/general-data">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Diagnose</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/disease', true)}`}>
          <NavLink className="menu-link" to="/disease">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Disease</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/symptom', true)}`}>
          <NavLink className="menu-link" to="/symptom">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Symptom</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/export-diagnose', true)}`}>
          <NavLink className="menu-link" to="/export-diagnose">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Diagnose Record</span>
          </NavLink>
        </li> */}

        <li className="menu-section ">
          <h4 className="menu-text">Animal</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/animalCategory', true)}`}
        >
          <NavLink className="menu-link" to="/animalCategory">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Animal Category</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive('/animalType', true)}`}>
          <NavLink className="menu-link" to="/animalType">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Animal Type</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
