import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Grid,
  MenuItem,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  Radio,
  RadioGroup,
  ListItemText,
  Modal,
  FormGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import React, { useState, useEffect } from 'react';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';
import { Autocomplete } from '@material-ui/lab';
import DiseaseModel from '../../models/Disease';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';
import DiagnoseModel from '../../models/Diagnose';
import Select from 'react-select';
import { Row, Col, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import _, { isInteger } from 'lodash';
import { StayCurrentLandscapeRounded } from '@material-ui/icons';
//import DiseaseSymptomSelectionModal from './DiseaseSymptomSelectionModal';
import consistencyChart from "../../../assets/stool_consistency_chart.png"

const CATEGORIES = {
  drinking: 'Drinking',
  eating: 'Eating',
  body_temperature: 'Body Temperature',
  general_condition: 'General Condition',
  food_type: 'Food Type',
  age: 'Age',
  gender: 'Gender'
};

const PreDiagnosePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { diagnoseId } = useParams();
  const [personName, setPersonName] = useState([]);

  const [animalOptions, setAnimalOptions] = useState([]);
  //const [bodyPartOptions, setBodyPartOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [drinkingOptions, setDrinkingOptions] = useState([]);
  const [eatingOptions, setEatingOptions] = useState([]);
  const [foodTypeOptions, setFoodTypeOptions] = useState([]);
  const [generalConditionOptions, setGeneralConditionOptions] = useState([]);
  const [symptomOptions, setSymptomOptions] = useState([]);
  const [generalResult, setGeneralResult] = useState([]);
  const [generalInfoCategories, setGeneralInfoCategories] = useState([]);

  const [selectedName, setSelectedName] = useState('');
  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  const [selectedTemperature, setSelectedTemperature] = useState(null);
  const [selectedDrinking, setSelectedDrinking] = useState(null);
  const [selectedEating, setSelectedEating] = useState(null);
  const [selectedFood, setSelectedFood] = useState(null);
  const [selectedGeneralCondition, setSelectedGeneralCondition] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [submitData, setSubmitData] = useState([]);
  const [eatings, setEatings] = useState([]);
  const [oldSymptomDiseaseID, setOldSymptomDiseaseID] = useState([]);
  const [deleted, setDeleted] = useState([])

  const [adaRacun, setAdaRacun] = useState(null);
  const [adaMuntah, setAdaMuntah] = useState(null);
  const [adaPoop, setAdaPoop] = useState(null);
  const [adaPee, setAdaPee] = useState(null);

  const [symptomDetailToEdit, setSymptomDetailToEdit] = useState(null);



  const getSymptomOptions = async () => {
    let resultSymptom = await SymptomModel.getAllActive();

    //console.log("aa", history.location.state)
    let tempSubmit = history.location.state?.preDiagnose != null ? history.location.state?.preDiagnose : []
    setSubmitData(tempSubmit)

    setAdaRacun(tempSubmit.some(el => el.symptom_id == 24))
    setAdaMuntah(tempSubmit.some(el => el.symptom_id == 25))
    setAdaPoop(tempSubmit.some(el => el.symptom_id == 26))
    setAdaPee(tempSubmit.some(el => el.symptom_id == 28))
    //console.log("COBA", tempSubmit.some(el => el.symptom_id == 24), tempSubmit)

    let res = resultSymptom.filter(el => el.id > 21 && el.id < 29);
    setSymptomOptions(res);
    //console.log("ini", resultSymptom)
  };

  console.log('SYTO', symptomOptions, selectedSymptoms);

  const initializeGeneralInfo = async () => {
    try {
      let result = await generalInfoModel.getAll();
      let resultSymptom = await SymptomModel.getAllActive();
      let oldSymptomDiseases = []

      let record = await DiagnoseModel.getDiagnoseById(diagnoseId)
      console.log("DFM", record?.diagnose_record_symptoms)
     
      let recordFiltered = record?.diagnose_record_symptoms?.filter(el => el.symptom_id > 21 && el.symptom_id < 29).map(obj=>{
        return {
          id: obj.id,
          symptom_id: obj.symptom_id,
          symptom_detail_id : obj?.symptom_detail_options?.symptoms_detail_id,
          symptom_detail_option_id: obj.symptom_detail_option_id
        }
      })

      recordFiltered.map(e => {
        if (!oldSymptomDiseases.includes(e.id)) {
          oldSymptomDiseases.push(e.id);
        }
      })

      setOldSymptomDiseaseID(oldSymptomDiseases)

      console.log("DFM INI", recordFiltered)
      setSubmitData(recordFiltered)
      setAdaRacun(recordFiltered.some(el => el.symptom_id == 24))
      setAdaMuntah(recordFiltered.some(el => el.symptom_id == 25))
      setAdaPoop(recordFiltered.some(el => el.symptom_id == 26))
      setAdaPee(recordFiltered.some(el => el.symptom_id == 28))

      console.log(resultSymptom);
      console.log(result);

      let unique = [];

      result.generalinformation.forEach(element => {
        if (!unique.includes(element.category)) {
          unique.push(element.category);
        }
      });

      console.log('hmm', unique);

      setGeneralInfoCategories(unique);

      setGeneralResult(result.generalinformation);

      setAnimalOptions(
        result.animal_types.map(a => ({
          value: a.id,
          label: a.animal_name
        }))
      );
      console.log(result.animal_types);
    } catch (e) {
      console.log('error dfm', e);
    }
  };

  const submit = async () => {
    //console.log(selectedSymptoms);
    if (selectedName == null || selectedName == '' || selectedName == []) {
      swal({
        title: 'Name',
        text: 'Name cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedAnimal == null || selectedAnimal == '' || selectedAnimal == []) {
      swal({
        title: 'Animal',
        text: 'Animal cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedAction == null || selectedAction == '' || selectedAction == []) {
      swal({
        title: 'Action',
        text: 'Action cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedTemperature == null || selectedTemperature == '' || selectedTemperature == []) {
      swal({
        title: 'Body Temperature',
        text: 'Body temperature cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedDrinking == null || selectedDrinking == '' || selectedDrinking == []) {
      swal({
        title: 'Drinking',
        text: 'Drinking cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedEating == null || selectedEating == '' || selectedEating == []) {
      swal({
        title: 'Food Frequency',
        text: 'Food frequency cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedFood == null || selectedFood == '' || selectedFood == []) {
      swal({
        title: 'Food Type',
        text: 'Food type cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedGeneralCondition == null || selectedGeneralCondition == '' || selectedGeneralCondition == []) {
      swal({
        title: 'General Condition',
        text: 'General condition cannot be empty',
        icon: 'error'
      });
      return;
    }
    if (selectedSymptoms == null || selectedSymptoms == '' || selectedSymptoms == []) {
      swal({
        title: 'Symptom',
        text: 'Symptoms cannot be empty',
        icon: 'error'
      });
      return;
    }

    let generalInfo = [];
    selectedDrinking?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedEating?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedFood?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedGeneralCondition?.forEach(element => {
      generalInfo.push(element.value);
    });
    selectedTemperature?.forEach(element => {
      generalInfo.push(element.value);
    });
    let body = {
      name: selectedName,
      action: selectedAction,
      animal_type_id: selectedAnimal?.value,
      general_informations: generalInfo
    };
    //console.log(body, selectedSymptoms)
    let symArray = [];
    selectedSymptoms?.forEach(el => {
      el.detail?.forEach(det => {
        det.options?.forEach(opt => {
          symArray.push({ symptom_id: opt.symptom_id, symptom_detail_options_id: opt.symptom_detail_option_id });
        });
      });
    });
    body.symptoms = symArray;
    console.log(body, 'BODY');

    try {
      let res = await DiseaseModel.create(body);
      //console.log("apa itu res", res)

      swal({
        title: 'Success',
        text: "Disease '" + res.name + "' has been added",
        icon: 'success'
      }).then(() => {
        window.location.href = `/disease/${res.id}`;
      });

      /* alert("Disease '" + res.name + "' has been added successfully")

      window.location.href = `/disease/${res.id}` */
    } catch (error) {
      swal({
        title: 'Error',
        text: 'Cannot create new disease',
        icon: 'error'
      });
    }
  };

  const reset = () => {
    setSelectedAction('');
    setSelectedAnimal(null);
    setSelectedDrinking(null);
    setSelectedEating(null);
    setSelectedFood(null);
    setSelectedGeneralCondition(null);
    setSelectedName('');
    setSelectedSymptoms(null);
    setSelectedTemperature(null);
  };

  const clearRacun = () => {
    let tempSubmit = [...submitData]
    tempSubmit = tempSubmit.filter(el => el.symptom_id != 24)
    setSubmitData(tempSubmit)
    setAdaRacun(false)

    let notDeleted = []
    tempSubmit.map(e => {
      if (!notDeleted.includes(e.id) && isInteger(e.id)) {
        notDeleted.push(e.id);
      }
    })
    let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x))
    console.log("coba", notDeleted, toBeDeleted)
    setDeleted(toBeDeleted)
  }

  const clearMuntah = () => {
    let tempSubmit = [...submitData]
    tempSubmit = tempSubmit.filter(el => el.symptom_id != 25)
    setSubmitData(tempSubmit)
    setAdaMuntah(false)

    let notDeleted = []
    tempSubmit.map(e => {
      if (!notDeleted.includes(e.id) && isInteger(e.id)) {
        notDeleted.push(e.id);
      }
    })
    let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x))
    console.log("coba", notDeleted, toBeDeleted)
    setDeleted(toBeDeleted)
  }

  const clearPoop = () => {
    let tempSubmit = [...submitData]
    tempSubmit = tempSubmit.filter(el => el.symptom_id != 26)
    setSubmitData(tempSubmit)
    setAdaPoop(false)

    let notDeleted = []
    tempSubmit.map(e => {
      if (!notDeleted.includes(e.id) && isInteger(e.id)) {
        notDeleted.push(e.id);
      }
    })
    let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x))
    console.log("coba", notDeleted, toBeDeleted)
    setDeleted(toBeDeleted)
  }

  const clearPee = () => {
    let tempSubmit = [...submitData]
    tempSubmit = tempSubmit.filter(el => el.symptom_id != 28)
    setSubmitData(tempSubmit)
    setAdaPee(false)

    let notDeleted = []
    tempSubmit.map(e => {
      if (!notDeleted.includes(e.id) && isInteger(e.id)) {
        notDeleted.push(e.id);
      }
    })
    let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x))
    console.log("coba", notDeleted, toBeDeleted)
    setDeleted(toBeDeleted)
  }

  const changeEating = id => {
    if (eatings.includes(id)) setEatings(eatings.filter(i => i !== id));
    else setEatings([...eatings, id]);
  };

  const changeSubmitData = (symptom_id, symptom_detail_id, option_id) => {
    
    let obj = {
      id: null,
      symptom_id: symptom_id,
      symptom_detail_id : symptom_detail_id,
      symptom_detail_option_id: option_id
    }
    let tempSubmit = [...submitData];
    if (submitData?.find(el => el?.symptom_detail_id == symptom_detail_id)) {
      let idx = submitData?.findIndex(el => el?.symptom_detail_id == symptom_detail_id)
      if (submitData?.find(el => el?.symptom_detail_option_id == option_id)) {
        let tempSubmit = [...submitData];
        //console.log('here', tempSubmit)
        tempSubmit.splice(idx, 1);
        //console.log('here', tempSubmit)
        setSubmitData(tempSubmit);
      } else {
        let tempSubmit = [...submitData];
        tempSubmit[idx] = obj
        setSubmitData(tempSubmit);
      }
    } else {
      
      tempSubmit.push(obj)
      setSubmitData(tempSubmit);
    }

    console.log("PDP4", tempSubmit)

    let notDeleted = []
    submitData.map(e => {
      if (!notDeleted.includes(e.id) && isInteger(e.id)) {
        notDeleted.push(e.id);
      }
    })


    let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x))
    console.log("coba", notDeleted, toBeDeleted)
    setDeleted(toBeDeleted)
  };

  const changeSubmitDataCheckbox = (symptom_id, symptom_detail_id, option_id) => {
    // alert("kepanggil")
    console.log("PDP", symptom_id, option_id)
    let obj = {
      id: null,
      symptom_id: symptom_id,
      symptom_detail_id : symptom_detail_id,
      symptom_detail_option_id: option_id
    }

    let foundedObject = submitData?.find(el=>el?.symptom_id == symptom_id && el?.symptom_detail_option_id == option_id)
    console.log("PDP7 FOUNDED", foundedObject, obj, submitData)

    if (foundedObject) {
      let foundIndex = submitData?.findIndex(el=>el?.symptom_id == symptom_id && el?.symptom_detail_option_id == option_id)
      let temp = [...submitData]
      temp.splice(foundIndex, 1)
      setSubmitData(temp)
      console.log("PDP7 SUBMITTING DELETED", foundIndex, submitData[foundIndex], submitData, temp)
    } else {
      let temp = [...submitData]
      temp.push(obj)
      console.log("PDP7 SUBMITTING", temp)

      setSubmitData(temp)
    }
  };

  /*   const changeSubmitData = (symptom_id, option_id) => {
      let obj = {
        id: 0, 
        symptom_id: symptom_id, 
        symptom_detail_option_id: option_id
      }
      if(JSON.stringify(submitData[symptom_id]) == JSON.stringify(obj)) {
        let tempSubmit = [...submitData]
        //console.log('here', tempSubmit)
        tempSubmit.splice(symptom_id, 1);
        //console.log('here', tempSubmit)
        setSubmitData(tempSubmit)
      } else {
        setSubmitData({ ...submitData, [category]: id });
      }
    } */

  const handleChange = (event, category) => {
    //console.log(event)
    setSubmitData({ ...submitData, [event.target.name]: event.target.value });
    //console.log("hmm", eatings)
  };

  console.log('hmm', eatings);
  console.log('here_main', submitData);

  useEffect(() => {
    getSymptomOptions();
    initializeGeneralInfo();
    delete history.location.state?.generalInfo?.animal_type;
    console.log('mana', history.location.state);
  }, []);

  const saveDataAndProceed=async()=>{
    try{

      if(submitData.length > 0) {
        let body = {
          progress_status: "waiting_symptoms",
          symptoms: submitData,
          deleted : deleted
        }
  
        // let record = await DiagnoseModel.getDiagnoseById(diagnoseId)

        let result = await DiagnoseModel.edit(diagnoseId, body);
  
        /* if(record.length > 0) {
          let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x));
          body.deleted = toBeDeleted
          result = await DiagnoseModel.edit(record[0].id, body);
        } else if(record.length == 0) {
          result = await DiagnoseModel.create(body)
        } */
  
        if(result) {
  
          history.push({
            pathname: '/diagnose/' + id + '/symptom-form/' + diagnoseId,
            state: {
              animal_type_id: history.location.state?.animal_type_id,
              generalInfo: {...history.location.state?.generalInfo},
              preDiagnose: submitData,
              deleted: deleted
            }
          });
          
        } else {
          alert("An error occurred")
        }
  
       
  
      } 
    }catch(e){
      console.error(e)
      alert("Terjadi Kesalahan")
    }
  }

  return (
    <div>
      <div style={{ marginTop: 'auto', marginBottom: 20 }}>
        <b style={{ fontSize: 20 }}>Pre-diagnose form</b>
        <br />
        Fill the pre-diagnose form below.
      </div>
      {/* <Card>
        <CardBody> */}
      <form>
        {symptomOptions.map(symptom => (
          <div>
            <h5>
              <b>{symptom.name}</b>
            </h5>

            {symptom.name === "Salah Makan" &&
                <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: 15,
                    borderWidth: 'thin',
                    borderColor: '#e6e1e1',
                    padding: 10,
                    marginBottom: 20
                  }}
                >
                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={12}>
                      Ada Salah Makan
                    </Grid>
                  </Grid>
                  <Row>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => setAdaRacun(true)}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaRacun == true ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaRacun == true ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaRacun == true ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={true}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Ya
                        </div>
                      </Col>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => clearRacun()}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaRacun == false ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaRacun == false ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaRacun == false ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={false}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Tidak
                        </div>
                      </Col>
                  </Row>
                </div>
            }

            {symptom.name === "Muntah" &&
                <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: 15,
                    borderWidth: 'thin',
                    borderColor: '#e6e1e1',
                    padding: 10,
                    marginBottom: 20
                  }}
                >
                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={12}>
                      Ada Muntah
                    </Grid>
                  </Grid>
                  <Row>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => setAdaMuntah(true)}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaMuntah == true ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaMuntah == true ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaMuntah == true ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={true}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Ya
                        </div>
                      </Col>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => clearMuntah()}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaMuntah == false ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaMuntah == false ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaMuntah == false ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={false}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Tidak
                        </div>
                      </Col>
                  </Row>
                </div>
            }

            {symptom.name === "Feces" &&
                <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: 15,
                    borderWidth: 'thin',
                    borderColor: '#e6e1e1',
                    padding: 10,
                    marginBottom: 20
                  }}
                >
                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={12}>
                      Kondisi Poop
                    </Grid>
                  </Grid>
                  <Row>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => setAdaPoop(true)}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaPoop == true ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaPoop == true ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaPoop == true ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={true}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Bermasalah
                        </div>
                      </Col>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => clearPoop()}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaPoop == false ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaPoop == false ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaPoop == false ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={false}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Normal
                        </div>
                      </Col>
                  </Row>
                </div>
            }

            {symptom.name === "Urine" &&
                <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: 15,
                    borderWidth: 'thin',
                    borderColor: '#e6e1e1',
                    padding: 10,
                    marginBottom: 20
                  }}
                >
                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={12}>
                      Kondisi Pee
                    </Grid>
                  </Grid>
                  <Row>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => setAdaPee(true)}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaPee == true ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaPee == true ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaPee == true ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={true}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Bermasalah
                        </div>
                      </Col>
                      <Col xl={2} md={3}>
                        <div
                          onClick={() => clearPee()}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: adaPee == false ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              adaPee == false ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer'
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={adaPee == false ? true : false}
                            // onChange={event => handleChange(event, el)}
                            value={false}
                            color="primary"
                            name={symptom}
                            //inputProps={{ 'aria-label': symptom_detail_option.options }}
                          />
                          Normal
                        </div>
                      </Col>
                  </Row>
                </div>
            }

            {symptom.symptoms_detail.map(symptom_detail => {

              console.log("PDP", symptom_detail, symptom_detail.type)

              if(symptom.name === "Salah Makan" && adaRacun == false) {
                return
              }
              
              if(symptom.name === "Muntah" && adaMuntah == false) {
                return
              }

              if(symptom.name === "Feces" && adaPoop == false) {
                return
              }

              if(symptom.name === "Urine" && adaPee == false) {
                return
              }

              if (symptom_detail.type === "checkbox") {
                console.log("PDP", "CHECKBOX")
                return <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: 15,
                    borderWidth: 'thin',
                    borderColor: '#e6e1e1',
                    padding: 10,
                    marginBottom: 20
                  }}
                >
                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item xs={12}>
                      {symptom_detail.detail_name}
                    </Grid>
                    {/* <Grid item xs={1}>
                        <Button style={{rotate: "180deg"}}>V</Button>
                      </Grid> */}
                  </Grid>
                  <Row>
                    {symptom_detail.symptom_detail_options.map(symptom_detail_option => {
                      console.log("PDP3", symptom_detail_option)
                      return <Col xl={2} md={3}>
                        {/* {JSON.stringify(el3) + ""} */}
                        <div
                          // onClick={() => alert("boye " + el3.options)}
                          onClick={() => {
                            changeSubmitDataCheckbox(symptom.id, symptom_detail.id, symptom_detail_option.id)
                            // alert("boye " + el3.options)
                          }}
                          style={{
                            borderStyle: 'solid',
                            borderRadius: 10,
                            borderWidth: 'thin',
                            borderColor: submitData?.find(el => el?.symptom_detail_option_id == symptom_detail_option.id) ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                            width: '100%',
                            marginBottom: 5,
                            backgroundColor:
                              submitData?.find(el => el?.symptom_detail_option_id == symptom_detail_option.id) ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                            cursor: 'pointer',
                            // padding : 7,
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <div>
                            <Checkbox
                              label={symptom_detail_option.options}
                              name={symptom_detail_option.id + '-name'}
                              type="checkbox"
                              id={symptom_detail.id + '-id'}
                              color='primary'
                              checked={submitData?.find(obj => obj.symptom_detail_option_id == symptom_detail_option.id) ? true : false}
                            ></Checkbox>
                            {/* <Form.Check
                                //custom
                                inline
                                label={el3.options+"/"+el3.id}
                                name={el3.id + '-name'}
                                type="checkbox"
                                id={e.id + '-id'}
                                checked={submitData?.find(obj => obj.symptom_detail_option_id == el3.id) ? true : false}
                            />*/}
                            {symptom_detail_option.options} 
                          </div>

                        </div>
                      </Col>
                  })}
                  </Row>
                </div>
              }

              return <div
                style={{
                  borderStyle: 'solid',
                  borderRadius: 15,
                  borderWidth: 'thin',
                  borderColor: '#e6e1e1',
                  padding: 10,
                  marginBottom: 20
                }}
              >
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                  <Grid item xs={12}>
                    {symptom_detail.detail_name}
                  </Grid>
                  {/* <Grid item xs={1}>
                        <Button style={{rotate: "180deg"}}>V</Button>
                      </Grid> */}
                  <Grid item xs={12}>
                    {symptom_detail.detail_name == "Score" && <img style={{borderRadius: 10, width: "70%"}} src={consistencyChart} />}
                  </Grid>
                    
                </Grid>
                
                <Row>
                  {symptom_detail.symptom_detail_options.map(symptom_detail_option => (
                    <Col xl={2} md={3}>
                      <div
                        onClick={() => changeSubmitData(symptom.id, symptom_detail.id, symptom_detail_option.id)}
                        style={{
                          borderStyle: 'solid',
                          borderRadius: 10,
                          borderWidth: 'thin',
                          borderColor: submitData?.find(el => el?.symptom_detail_option_id == symptom_detail_option.id) ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                          width: '100%',
                          marginBottom: 5,
                          backgroundColor:
                            submitData?.find(el => el?.symptom_detail_option_id == symptom_detail_option.id) ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                          cursor: 'pointer'
                          //marginTop: "-1.5vh"
                        }}
                      >
                        {/* <Form>
                          <div
                            onClick={() => changeEating(e.id)}
                            key={`inline-${e.value}`}
                            className="mb-3"
                            style={{
                              borderStyle: 'solid',
                              borderRadius: 10,
                              borderWidth: 'thin',
                              borderColor: eatings.includes(e.id) ? 'rgba(0, 55, 255, 0.5)' : '#bfbaba',
                              width: '100%',
                              padding: 7,
                              backgroundColor: eatings.includes(e.id) ? 'rgba(0, 155, 255, 0.2)' : 'transparent',
                              cursor: 'pointer'
                              //marginTop: "-1.5vh"
                            }}
                          >
                            <Form.Check
                              //custom
                              inline
                              label={e.value}
                              name={e.value + '-name'}
                              type="checkbox"
                              id={e.value + '-id'}
                              checked={eatings.includes(e.id)}
                              //onClick={() => changeEating(e.id)}
                            />
                          </div>
                        </Form> */}

                        <Radio
                          checked={submitData?.find(el => el?.symptom_detail_option_id == symptom_detail_option.id) ? true : false}
                          // onChange={event => handleChange(event, el)}
                          value={symptom_detail_option.id}
                          color="primary"
                          name={symptom}
                          inputProps={{ 'aria-label': symptom_detail_option.options }}
                        />
                        {symptom_detail_option.options}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            })}
          </div>
        ))}
      </form>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid className="d-flex align-items-center justify-content-center" item xs={12}>
          <div
            //onClick={()=>{history.push("/diagnose/" + id + "/general-data/" + patientID)}}
            onClick={() => {
              history.push({
                pathname: '/diagnose/' + id + '/general-data/' + diagnoseId,
                state: {
                  animal_type_id: history.location.state?.animal_type_id,
                  generalInfo: { ...history.location.state?.generalInfo, ...submitData, eatings: eatings }
                }
              });
            }}
            className="d-flex align-items-center justify-content-center"
            style={{ background: '#1776CF', width: 50, height: 50, borderRadius: 5, cursor: 'pointer' }}
          >
            <FaArrowLeft size={20} color="white" />
          </div>
          <div style={{ flex: 1 }}></div>
          <div
            onClick={() => {
              console.log("PDP6", submitData)
              saveDataAndProceed()

            }}
            className="d-flex align-items-center justify-content-center"
            style={{ background: '#1776CF', width: 50, height: 50, borderRadius: 5, cursor: 'pointer' }}
          >
            <FaArrowRight size={20} color="white" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PreDiagnosePage;
