import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { DataTableLocal as DataTable } from '../../../components/common/DataTable';
import LoadingCard from 'app/components/common/LoadingCard/LoadingCard';
import swal from 'sweetalert2';
import { fetchMedicalRecordListByPatientID } from 'redux/medicalRecord/list';
import MedicalRecord from 'app/models/MedicalRecord';
import Appointment from '../../../models/Appointment';
import { TextField } from 'app/components/common/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Files from '../../../models/File';
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import { Description } from '@material-ui/icons';

const docuType = [
  {
    label: "Operasi",
    value: "OPERASI"
  },
  {
    label: "Penitipan",
    value: "PENITIPAN"
  },
  {
    label: "Rawat Inap",
    value: "RAWAT INAP"
  },
]

/* const consentText = [
  {
    key: "OPERASI",
    text: "Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi Lorem Ipsum Operasi"
  },
  {
    key: "PENITIPAN",
    text: "Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan"
  },
  {
    key: "RAWAT INAP",
    text: "Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap"
  }
] */

const consentText = {
  "OPERASI": 'Dengan ini saya memberikan kuasa sepenuhnya dan langsung kepada team dokter hewan di "drh. Rajanti & Friends" untuk melaksanakan prosedur yang diperlukan untuk pengobatan/operasi yang dianggap perlu untuk hewan saya. \n\n \
              Saya mengerti sepenuhnya bahwa prosedur dan tindakan medis yang dilakukan dapat menimbulkan resiko dan tidak ada jaminan yang diberikan untuk hasil atau kesembuhannya. \n\n \
              Saya menyadari sepenuhnya bahwa hewan saya bisa tertular penyakit atau kutu dan dapat terjadi shock mendadak yang dapat mengakibatkan kematian. Saya juga mengerti bisa saja terjadi resiko hewan saya hilang atau lepas karena ketidaksengajaan. \n\n \
              Saya tidak akan menuntut baik secara materil maupun secara moril bila terjadi sesuatu yang tidak kita inginkan tersebut. \n\n \
              Saya setuju untuk membayar penuh jasa yang telah diberikan, termasuk yang dianggap perlu untuk pengobatan ataupun komplikasi yang komplikasi yang tidak diperkirakan sebelumnya. \n\n \
              Saya setuju untuk membayar lunas sebelum hewan saya dibawa/diantar pulang. \n\n \
              Saya memberikan kekuasaan penuh kepada pihak "drh. Rajanti & Friends" untuk mengatur status kepemilikan baru atau mengurus hewan saya bila saya tidak mengambil hewan yang saya titipkan dalam waktu 3 (tiga) hari dari tanggal pengambilan yang telah disepakati bersama, dengan atau tanpa pemberitahuan terlebih dahulu. \n\n \
              Saya paham bahwa klinik tidak mengijinkan penitipan barang seperti kandang, pet cargo, selimut, kalung dan leash, tempat makan dan minum, mainan, dsb selama hewan dititipkan di klinik. Saya tidak akan meminta pertanggungjawaban apabila terjadi kehilangan barang-barang tersebut.',
  "PENITIPAN": "Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan Lorem Ipsum Penitipan",
  "RAWAT INAP": "Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap Lorem Ipsum Rawat Inap"
}

const DoctorSignConsent = ({ data, showSig, setShowSig }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { role } = useSelector(state => state.auth);
  const { ID } = useSelector(state => state.auth);

  let sigCanvasDoctor = useRef({});

  const initialize = async () => {

    let AppointmentModel = new Appointment();
  }

  const handleCloseSignature = () => {
    setShowSig(false);
  };

  const dataURLtoBlob = dataUrl => {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const saveSignature = async () => {
    try {
      let FileModel = new Files();
      if (sigCanvasDoctor.current) {
        const dataURL2 = sigCanvasDoctor.current.toDataURL();
        let blob2 = dataURLtoBlob(dataURL2);
        blob2 = new File([blob2], 'signature_doctor.png');
        let result2 = await FileModel.uploadFile(blob2);
        if (!result2.location) {
          swal.fire({
            title: 'Error',
            text: 'Unable to upload signature, please try again later',
            icon: 'error',
            confirmButtonText: 'Go back',
            customClass: {
              container: 'swal-z'
            }
          });
          return;
        }
        let obj = {
          doctor_id: parseInt(ID),
          doctor_signature_url: result2.location
        };
        let appointmentModel = new Appointment();
        let updatedConsent = await appointmentModel.editAppointmentUserConsent(data?.id, obj)
        //let updateResult = await appointmentModel.editAppointment(data.id, obj);
        console.log("cek submit", obj)
        if (updatedConsent) {
          swal
            .fire({
              title: 'success',
              text: 'successfully saving data',
              icon: 'success',
              confirmButtonText: 'Go back',
              customClass: {
                container: 'swal-z'
              }
            })
            .then(e => {
              window.location.reload();
            });
          setShowSig(false);
        }
      }
    } catch (e) {
      swal.fire({
        title: 'Error',
        text: 'Something wrong, please try again later',
        icon: 'error',
        confirmButtonText: 'Go back',
        customClass: {
          container: 'swal-z'
        }
      });
      return;

      setShowSig(false);
    }
  };

  const clearSignature = () => {
    sigCanvasDoctor.current.clear();
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <Dialog aria-labelledby="customized-dialog-title" open={showSig}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseSignature}>
          Consent Form
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <label>Jenis Konsen</label>
            <p>{data?.document_type}</p>

            <br />

            <p style={{ whiteSpace: "pre-line" }}>
              {consentText[data?.document_type]}
            </p>

            <hr/>

            <label>Pasien</label>
            <p>{data?.patient_name}</p>

            <hr/>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <label>Keterangan Tambahan</label>
              <p>{data.description == null ? '-' : data.description}</p>
            </div>

            <hr/>

            <label>TTD Pemilik/Pembawa</label>
            <br/>
            {/* <br /> */}
            <img
              style={{ height: 80, objectFit: "contain" }}
              src={data?.signature_url} />

            <div style={{ marginTop: 10, marginBottom: 10, display: 'flex' }}>
              <div style={{ width: '40%' }}>
                <div>{data?.signee_name}</div>
                <div>{data?.signee_role}</div>
              </div>
            </div>
            <hr/>
            <label>TTD Dokter</label>
            {data.doctor_signature_url == null ?
              <>
                <SignatureCanvas
                  ref={sigCanvasDoctor}
                  penColor="black"
                  backgroundColor="#eee"
                  canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                />
              </>
              :
              <>
                <br />
                <img
                  style={{ height: 80, objectFit: "contain" }}
                  src={data?.doctor_signature_url} />
                <p>{data?.doctor_name}</p>
              </>
            }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {data.doctor_signature_url == null ?
            <>
              <Button autoFocus onClick={saveSignature} color="primary">
                Save changes
              </Button>
              <Button onClick={clearSignature} color="primary">
                Reset
              </Button>
            </>
            :
            <></>
          }

          <Button
            onClick={e => {
              setShowSig(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default DoctorSignConsent;
