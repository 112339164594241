import ApiRequest from '../../utils/APIRequest2';

export default class Diagnose {

  static getAll = async () => {
    return await ApiRequest.set('v1/diagnoses', 'GET');
  };

  static getAllRecords = async () => {
    return await ApiRequest.set('v1/diagnoses/all', 'GET');
  };

  static getAllRecordUsers = async () => {
    return await ApiRequest.set('v1/diagnoses/users', 'GET');
  }

  static getAllRecordUsersByRecordIdAndUserId = async (record_id, user_id) => {
    return await ApiRequest.set('v1/diagnoses/users/' + record_id + '/' + user_id, 'GET');
  }

  static createRecordUser = async (values) => {
    return await ApiRequest.set('v1/diagnoses/user', 'POST', values);
  }

  static getAllDiagnoseResultDiseases = async () => {
    return await ApiRequest.set('v1/diagnoses/diseases', 'GET');
  }

  static getAllDiagnoseResultDiseasesByRecordId = async (record_id) => {
    return await ApiRequest.set('v1/diagnoses/diseases/record/' + record_id, 'GET');
  }

  static getAllDiagnoseResultDiseasesByDiseaseId = async (disease_id) => {
    return await ApiRequest.set('v1/diagnoses/diseases/disease/' + disease_id, 'GET');
  }

  static createDiagnoseResultDisease = async (values) => {
    return await ApiRequest.set('v1/diagnoses/disease', 'POST', values);
  }

  static getDiagnoseById = async (id) => {
    return await ApiRequest.set('v1/diagnose/' + id, 'GET');
  };

  static getByAppointmentUserandPatientId = async (id, patientId) => {
    return await ApiRequest.set('v1/diagnose/' + id + "/" + patientId, 'GET');
  };

  static getByAppointmentPatientId = async (patientId) => {
    return await ApiRequest.set(`v1/diagnose_record/${patientId}`, 'GET');
  };

  /* deleteDiseaseById = async (id) => {
    return await ApiRequest.set('v1/disease/'+id, 'DELETE')
  } */

  static edit = async (id, values) => {
    return await ApiRequest.set('v1/diagnose/'+id, 'PUT', values)
  } 

  static saveDiagnoseResult = async (id, diseaseId) => {
    return await ApiRequest.set(`v1/diagnose/${id}/diagnoseResult`, 'PUT', {
      diagnose_result_id : diseaseId
    })
  } 

  static create = async (values) => {
    return await ApiRequest.set('v1/diagnose', 'POST', values)
  }

}
