import HorizontalSeparator from 'app/components/common/HorizontalSeparator/HorizontalSeparator';
import {
  Button,
  Grid,
  MenuItem,
  //Select,
  InputLabel,
  Input,
  OutlinedInput,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { useState, useEffect } from 'react';
import MultipleSelect from 'app/components/common/MultipleSelect/MultipleSelect';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Autocomplete } from '@material-ui/lab';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import SymptomModel from '../../models/Symptom';
import generalInfoModel from '../../models/GeneralInformation';
import AdminModel from 'model/AdminModel';

const AdminEditPage = () => {
  const { id } = useParams();
  const history = useHistory()

  const [adminUsername, setAdminUsername] = useState('')
  const [symptomName, setSymptomName] = useState('');
  const [animalOptions, setAnimalOptions] = useState([]);
  //const [bodyPartOptions, setBodyPartOptions] = useState([]);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [drinkingOptions, setDrinkingOptions] = useState([]);
  const [eatingOptions, setEatingOptions] = useState([]);
  const [foodTypeOptions, setFoodTypeOptions] = useState([]);
  const [generalConditionOptions, setGeneralConditionOptions] = useState([]);
  //const [tempOptions, setTempOptions] = useState([]);

  const [selectedAnimal, setSelectedAnimal] = useState([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedTemperature, setSelectedTemperature] = useState([]);
  const [selectedDrinking, setSelectedDrinking] = useState([]);
  const [selectedEating, setSelectedEating] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);

  const [reactSelectValue, setReactSelectValue] = useState([]);

  const [symptomDetails, setSymptomDetails] = useState([
    //{name: '', options: ''}
  ]);

  let typeOptions = [
    {
      value: 'checkbox',
      label: 'Checkbox'
    },
    {
      value: 'radio',
      label: 'Radio'
    },
  ]

  let bodyPartOptions = [
    {
      value: 'Mouth',
      label: 'Mouth'
    },
    {
      value: 'Nose',
      label: 'Nose'
    },
    {
      value: 'Stomach',
      label: 'Stomach'
    },
    {
      value: 'Eye',
      label: 'Eye'
    },
    {
      value: 'Fur',
      label: 'Fur'
    },
    {
      value: 'Leg',
      label: 'Leg'
    }, 
    {
      value: 'Chest',
      label: 'Chest'
    },
    {
      value: 'Back',
      label: 'Back'
    },
    {
      value: 'Ribs',
      label: 'Ribs'
    },
    {
      value: 'Bone',
      label: 'Bone'
    },
    {
      value: 'Nerves',
      label: 'Nerves'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ];

  const getAdminData = async () => {
    try {

        let admin = await AdminModel.getAdminById(id)
        console.log("INI ADMIN", admin)

        setAdminUsername(admin.user_name)
      
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async e => {
    try {
      let body = {
        user_name: adminUsername,
      };

      console.log('submitting', body);

      let result = await AdminModel.edit(id, body);

      swal({
        title: 'Success',
        text: 'Admin username has been updated',
        icon: 'success'
      }); /* .then(
          window.location.reload()
        ) */
    } catch (e) {
      console.log(e);

      swal({
        title: 'Error',
        text: e.error_message ? e.error_message : 'An Error Occurred',
        icon: 'error',
        customClass: {
          container: 'swal-z'
        }
      });
    }
  };

  useEffect(() => {
    getAdminData();
    console.log(id);
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Admin details">
          {/* <CardHeaderToolbar>
            <Button color={'secondary'} variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // id="name"
                  value={adminUsername}
                  onChange={e => setAdminUsername(e.target.value)}
                  label="Username *"
                  input={<OutlinedInput label="Tag" />}
                  // helperText={touched.name ? errors.name : ''}
                  // error={touched.name && errors.name ? true : false}
                />
              </Grid>
              
            </Grid>

            <br />
          </form>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button disabled={adminUsername == '' ? true : false} color={'primary'} variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminEditPage;
