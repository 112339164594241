import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import SymptomModel from '../../models/Symptom';
import DiagnoseModel from '../../models/Diagnose';
import ButtonLink from '../../components/common/ButtonLink';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import { fetchDiseaseList } from '../../../redux/disease/list';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

const ExportDiagnosis = () => {
  const [records, setRecords] = useState([]);
  const [rowExport, setRowExport] = useState([]);
  const initialize = async () => {
    try {
      const result = await DiagnoseModel.getAllRecords();
      console.log('records', result);

      const result2 = await SymptomModel.getAllActiveOptionIds();
      //console.log('options', result2);

      let rows = []
      rows.push(result2)

      if(result) {
        result.forEach(el => {
          let arrSymptoms = []

          el.diagnose_record_symptoms?.map(el => {
            arrSymptoms.push(el.symptom_detail_option_id)
          })
          //console.log("cek", arrSymptoms)

          let row = []
  
          result2.forEach(el => {
            if(arrSymptoms.includes(el)) {
              row.push(1)
            } else {
              row.push(0)
            }
          })
          rows.push(row)
        })
      }
 
      

      //console.log("rows", rows)
      setRowExport(rows)

      setRecords(result);
    } catch (e) {
      console.log('failed to retrive', e);
    }
  };
  useEffect(() => {
    initialize();
    // dispatch(fetchDiseaseList());
  }, []);

  return (
    <Card>
      <CardHeader title="Diagnose records">
        <CardHeaderToolbar>
          {/* <ButtonLink link="/disease/add" variant="contained" color="primary">
            Export
          </ButtonLink> */}
          <CSVLink
            filename={
              "TestingExport.csv"
            }
            data={rowExport}
            /* headers={[
              { label: 'First Name', key: 'details.firstName' },
              { label: 'Last Name', key: 'details.lastName' },
              { label: 'Job', key: 'job' }
            ]} */
          >
            Download me
          </CSVLink>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody></CardBody>
    </Card>
  );
};

export default ExportDiagnosis;
