import {
  Grid,
  Chip,
  Radio,
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import SymptomModel from '../../models/Symptom';
import { Row, Col, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DiseaseSymptomSelectionModal from '../disease/DiseaseSymptomSelectionModal';
import AppointmentModel from "../../../model/AppointmentModel";
import DiagnoseModel from '../../models/Diagnose';
import { useDispatch, useSelector } from 'react-redux';
import mouthCategoryImage from "../../../assets/bodypart/mouth.png"
import eyeCategoryImage from "../../../assets/bodypart/eye.png"
import noseCategoryImage from "../../../assets/bodypart/nose.png"
import stomachCategoryImage from "../../../assets/bodypart/stomach.png"
import furCategoryImage from "../../../assets/bodypart/fur.png"
import legCategoryImage from "../../../assets/bodypart/leg.png"
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { fetchDoctorList } from '../../../redux/doctor/list';
import generalInfoModel from '../../models/GeneralInformation';
import Select from 'react-select';
import Diagnose from '../../models/Diagnose';
import UserModel from 'model/UserModel';
import SelectUserModal from './SelectUserModal';
import { diagnose } from 'redux/diagnosis';
import AdminModel from 'model/AdminModel';

const CATEGORIES = {
  "drinking": "Drinking",
  "eating": "Eating",
  "body_temperature": "Body Temperature",
  "general_condition": "General Condition",
  "food_type": "Food Type",
  "age": "Age",
  "gender": "Gender"
}

const BODY_PARTS = [
  { key: "mouth", label: "Mouth", image: mouthCategoryImage, },
  { key: "eye", label: "Eye", image: eyeCategoryImage, },
  { key: "nose", label: "Nose", image: noseCategoryImage, },
  { key: "stomach", label: "Stomach", image: stomachCategoryImage, },
  { key: "fur", label: "Fur", image: furCategoryImage, },
  { key: "leg", label: "Leg", image: legCategoryImage }
]


const GENDER = {
  "MALE": 6,
  "FEMALE": 7,
}

const ANIMAL = {
  "DOG": 1,
  "CAT": 2,
  "HAMSTER": 3
}

const ADMINS = [
  {label: 'rajanticlinic', value: 102},
  {label: 'admin01', value: 100113},
  {label: 'admin', value: 100214},
]


const AnimalDataPage = () => {
  const { id } = useParams();
  const { diagnoseId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { role } = useSelector(state => state.auth);
  const { ID } = useSelector(state => state.auth);
  const { user } = useSelector(state => state.auth);
  console.log("ROLE INI", role)

  const { data } = useSelector(state => state.doctor.list);
  const [generalInfoCategories, setGeneralInfoCategories] = useState([]);
  const [generalResult, setGeneralResult] = useState([]);
  const [animalResult, setAnimalResult] = useState([]);
  const [submitData, setSubmitData] = useState({})
  const [doctorList, setDoctorList] = useState([])
  const [adminList, setAdminList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [showSelectUser, setShowSelectUser] = useState(false)

  const checkRecordUser = async () => {
    let res = await DiagnoseModel.getAllRecordUsersByRecordIdAndUserId(diagnoseId, ID)
    console.log("coba", res)

    if(user.includes("perkedatangan") || user.includes("Perkedatangan") || role == "ADMIN") {
      console.log('KOSONG')
      setShowSelectUser(true)
    } else if(res.length == 0 && role == "DOCTOR") {     
      let body = {
        diagnose_record_id: parseInt(diagnoseId),
        user_id: parseInt(ID)
      }
      let newRecord = await DiagnoseModel.createRecordUser(body)
      
      if(newRecord) {
        console.log("NEW RECORD USER ADDED")
      } else {
        console.log("ERROR ADDING NEW RECORD USER")
      }
      
    } 
  }

  const initializeGeneralInfo = async () => {
    try {
      let result = await generalInfoModel.getAll();

      let res = await AppointmentModel.getAppointmentDetail(id)

      //let testing = await DiagnoseModel.getByAppointmentUserandPatientId(id, patientID)
      let testing = await DiagnoseModel.getDiagnoseById(diagnoseId)
      console.log("dapet", testing)

      let patient = res.patients.filter(obj => obj.id == testing.patient_id)[0]

      console.log("ADETAIL", patient, testing.patient_id)
      
      let temp = {...submitData}
      console.log("BDETAIL", patient.animal_type, patient.patient_gender, GENDER["MALE"])
      temp["animal_type"] = ANIMAL[patient.animal_type]
      temp["gender"] = GENDER[patient.patient_gender]
      temp["age"] = 3

      let today = Date.now()
      let birthdate = new Date(patient.birthdate)
      let diffTime = Math.abs(today - birthdate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      //let diffMonths = (diffDays % 365) / 30
      let age = Math.floor(diffDays / 365)

      if(age > 11) {
        temp["age"] = 5
      } else if(age >= 8) {
        temp["age"] = 4
      } else if(age >= 4) {
        temp["age"] = 3
      } else if(age >= 1) {
        temp["age"] = 2
      } else if(age >= 0) {
        temp["age"] = 1
      }
      //let age = today.diff(birthdate, "y", true)
      console.log("umur", age)

      setSubmitData(temp)

      console.log(result);

      let unique = [];

      result.generalinformation.forEach(element => {
        if (!unique.includes(element.category)) {
          unique.push(element.category);
        }
      });

      console.log("hmm", unique)

      setGeneralInfoCategories(unique)

      setGeneralResult(result.generalinformation)
      setAnimalResult(result.animal_types)

      console.log(result.animal_types);
      
    } catch (e) {
      console.log('error', e);
    }
  };

  const changeSubmitData = (category, id) => {
    if(submitData[category] == id) {
      let tempSubmit = {...submitData}
      //console.log('here', tempSubmit)
      delete tempSubmit[category]
      //console.log('here', tempSubmit)
      setSubmitData(tempSubmit)
    } else {
      setSubmitData({ ...submitData, [category]: id });
    }
  }

  const initializeDoctorAndAdmin = async () => {
    setDoctorList(data.map(obj=>{
      return {
        label : obj.doctor_name,
        value : obj.user_id
      }
    }))

    let admins = await AdminModel.getAllAdmins()

    setAdminList(admins.map(obj=>{
      return {
        label : obj.user_name,
        value : obj.id
      }
    }))

    console.log("users", doctorList, adminList)
  }

  useEffect(() => {
    dispatch(fetchDoctorList());
  }, []);

  useEffect(() => {
    checkRecordUser();
  }, []);

  useEffect(() => {
    initializeGeneralInfo();
    initializeDoctorAndAdmin();
  }, [data]);

  return (
    <div>
      <div style={{ marginTop: "auto", marginBottom: 20 }}>
        <b style={{ fontSize: 20 }}>General Information</b>
        <br />
        Please fill in this form with the patient's data.
      </div>
      {/* <Card>
        <CardBody> */}
      <Row>
        <Col md={12}>
          <div style={{
            borderStyle: "solid",
            borderRadius: 15,
            borderWidth: "thin",
            borderColor: "#e6e1e1",
            padding: 10,
            marginBottom: 20,
            paddingTop : 20
          }}>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <b style={{marginBottom : 5}}>Animal Type</b>
                <Row style={{marginTop : 10}}>
                  {
                    animalResult.map(e => {
                      return <Col md={3}>
                        <div
                          onClick={() => changeSubmitData("animal_type", e.id)}
                          style={{
                            borderStyle: "solid",
                            borderRadius: 10,
                            borderWidth: "thin",
                            borderColor: submitData['animal_type'] == e.id ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                            width: "100%",
                            marginBottom: 5,
                            backgroundColor: submitData['animal_type'] == e.id ? "rgba(0, 155, 255, 0.2)" : "transparent",
                            cursor: "pointer"
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={submitData['animal_type'] == e.id}
                            //onChange={event => handleChange(event, 'animal_type')}
                            value={e.id}
                            color="primary"
                            name={'animal_type'}
                            inputProps={{ 'aria-label': e.value }}
                          />
                          {e.animal_name}
                          {/* {e.value} */}
                        </div>
                      </Col>
                    })
                  }

                </Row>

              </Grid>
              <Grid item xs={12}>
                <b style={{marginBottom : 5}}>Gender</b>
                <Row style={{marginTop : 10}}>
                  {
                    generalResult.filter(e => e.category == 'gender').map(e => {
                      return <Col md={3}>
                        <div
                          onClick={() => changeSubmitData(e.category, e.id)}
                          style={{
                            borderStyle: "solid",
                            borderRadius: 10,
                            borderWidth: "thin",
                            borderColor: submitData['gender'] == e.id ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                            width: "100%",
                            marginBottom: 5,
                            backgroundColor: submitData['gender'] == e.id ? "rgba(0, 155, 255, 0.2)" : "transparent",
                            cursor: "pointer"
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={submitData['gender'] == e.id}
                            //onChange={event => handleChange(event, 'gender')}
                            value={e.id}
                            color="primary"
                            name={'gender'}
                            inputProps={{ 'aria-label': e.value }}
                          />
                          {e.value} 
                          {/* {e.value} */}
                        </div>
                      </Col>
                    })
                  }

                </Row>

              </Grid>
              <Grid item xs={12}>
                <b style={{marginBottom : 5}}>Age</b>
                <Row style={{marginTop : 10}}>
                  {
                    generalResult.filter(e => e.category == 'age').map(e => {
                      return <Col md={3}>
                        <div
                          onClick={() => changeSubmitData(e.category, e.id)}
                          style={{
                            borderStyle: "solid",
                            borderRadius: 10,
                            borderWidth: "thin",
                            borderColor: submitData['age'] == e.id ? "rgba(0, 55, 255, 0.5)" : "#bfbaba",
                            width: "100%",
                            marginBottom: 5,
                            backgroundColor: submitData['age'] == e.id ? "rgba(0, 155, 255, 0.2)" : "transparent",
                            cursor: "pointer"
                            //marginTop: "-1.5vh"
                          }}
                        >
                          <Radio
                            checked={submitData['age'] == e.id}
                            //onChange={event => handleChange(event, 'age')}
                            value={e.id}
                            color="primary"
                            name={'age'}
                            inputProps={{ 'aria-label': e.value }}
                          />
                          {e.value}
                          {/* {e.value} */}
                        </div>
                      </Col>
                    })
                  }

                </Row>

              </Grid>
            </Grid>
          </div>

        </Col>
      </Row>

      {/* <div style={{ marginTop: "auto", marginBottom: 20 }}>
        Please fill in the user responsible for this diagnose.
      </div>
      <Row>
        <Col md={12}>
          <div style={{
            borderStyle: "solid",
            borderRadius: 15,
            borderWidth: "thin",
            borderColor: "#e6e1e1",
            padding: 10,
            marginBottom: 20,
            paddingTop : 20
          }}>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <b style={{marginBottom : 5}}>{role}</b>
                <Row style={{marginTop : 10}}>
          
                  <div style={{
                    width: '33%',
                    padding: 10
                  }}>
                    <Select
                      options={role == 'DOCTOR' ? doctorList : ADMINS}
                      value={selectedUser}
                      onChange={(value)=>{setSelectedUser(value)}}
                    />
                  </div>
                </Row>

              </Grid>
              </Grid>
          </div>
        </Col>
      </Row> */}

      <Grid container style={{ marginBottom: 10 }}>
        <Grid className='d-flex align-items-center justify-content-center' item xs={12}>
          {/* <div
            onClick={() => { history.push("/") }}
            className='d-flex align-items-center justify-content-center'
            style={{ background: "#1776CF", width: 50, height: 50, borderRadius: 5, cursor: 'pointer' }}>
            <FaArrowLeft size={20} color='white' />
          </div> */}
          <div style={{ flex: 1 }}>

          </div>
          <div
            onClick={() => { history.push({
              pathname: "/diagnose/" + id + "/pre-diagnose/" + diagnoseId,
              state: {
                animal_type_id: submitData['animal_type'],
                generalInfo: {...submitData}
              }
            })}}
            className='d-flex align-items-center justify-content-center'
            style={{ background: "#1776CF", width: 50, height: 50, borderRadius: 5, cursor: 'pointer' }}>
            <FaArrowRight size={20} color='white' />
          </div>
        </Grid>
      </Grid>


      <SelectUserModal
          userList={role == 'DOCTOR' ? doctorList : adminList}
          recordID={diagnoseId}
          open={showSelectUser}
          onClose={() => {
            setShowSelectUser(false);
          }}
        />
    </div>
  );
};

export default AnimalDataPage;
