import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    MenuItem,
    IconButton,
    Typography
} from '@material-ui/core';
import swal from 'sweetalert2';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Link as LinkIcon } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addDays, format, parseISO, startOfDay } from 'date-fns';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { TextField } from 'app/components/common/TextField';
import { ActionBarAdd, ActionBarEdit } from 'app/components/common/ActionBar';
import KeyboardDatePicker from 'app/components/common/KeyboardDatePicker';
import SignatureCanvas from 'react-signature-canvas'
import { fetchCustomerList } from 'redux/customer/list';
import { fetchPatientList } from 'redux/patient/list';
import { fetchAppointmentTypeList } from 'redux/appointmentType/list';
import { fetchDoctorListByAppointmentType } from 'redux/doctor/listByAppointmentType';
import { fetchAppointmentSlotByDoctorAndDateAndAppointmentType } from 'redux/appointmentSlot/list';
import { addAppointment } from 'redux/appointment/ops';
import { editAppointment } from 'redux/appointment/edit';

import Appointments from '../../../models/Appointment';

import Files from '../../../models/File';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

import moment from "moment"
import { useRef } from 'react';


const AppointmentForm = ({ data, isEditing, headerToolbar }) => {
    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const { role } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(fetchCustomerList());
        dispatch(fetchPatientList());
        dispatch(fetchAppointmentTypeList());
    }, []);

    const { data: customerList } = useSelector(state => state.customer.list);
    const { data: patientList } = useSelector(state => state.patient.list);
    const { data: appointmentTypeList } = useSelector(
        state => state.appointmentType.list
    );
    const { data: doctorListByAppointmentType } = useSelector(
        state => state.doctor.listByAppointmentType
    );

    const { data: appointmentSlotList } = useSelector(
        state => state.appointmentSlot.list
    );

    const { completed, isLoading } = useSelector(state => state.appointment.ops);

    useEffect(() => {
        if (!data && completed) {
            history.replace();
            resetForm();
        }
    }, [completed]);

    const getInitialCustomer = customerID =>
        customerList.find(customer => customer.id === customerID);

    const getInitialPatient = patientID =>
        patientList.find(patient => patient.id === patientID);

    const getInitialDoctor = doctorID => {
        const selectedDoctor = doctorListByAppointmentType.find(
            doctor => doctor.doctor_id === doctorID
        );
        return selectedDoctor;
    };

    const getInitialDate = date => {
        if (date) {
            return parseISO(date);
        }
        return;
    };

    const getInitialAppointmentSlot = (startDate, endDate) => {
        if (startDate && endDate) {
            const startDateStr = format(parseISO(startDate), 'HH:mm');
            const endDateStr = format(parseISO(endDate), 'HH:mm');

            return `${startDateStr} - ${endDateStr}`;
        }
        return;
    };

    console.log("DDATA", data);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
        resetForm
    } = useFormik({
        initialValues: {
            customer: getInitialCustomer(data?.customer_id) || '',
            patient: getInitialPatient(data?.patient_id) || '',
            appointmentType:
                data?.booking_type_name || state?.booking_type_name || '',
            doctor:
                getInitialDoctor(data?.doctor_id) ||
                getInitialDoctor(state?.doctor_id) ||
                '',
            coDoctor:
                data?.co_doctor_name,
            date:
                getInitialDate(data?.start_time) ||
                getInitialDate(state?.start_time) ||
                null,
            appointmentSlot:
                getInitialAppointmentSlot(data?.start_time, data?.end_time) ||
                state?.id ||
                '',
            description: data?.additional_storage || '',
            paymentProof: data?.proof_of_payment || null
        },
        validationSchema: Yup.object({
            customer: Yup.string().required('Cannot be empty'),
            patient: Yup.string().required('Cannot be empty'),
            appointmentType: Yup.string().required('Cannot be empty'),
            doctor: Yup.string().required('Cannot be empty'),
            date: Yup.date().required('Cannot be empty'),
            appointmentSlot: Yup.string().required('Cannot be empty'),
            description: Yup.string().max(3000)
        }),
        onSubmit: values => {
            if (!data) {
                const formattedValues = {
                    patientID: values.patient.id,
                    appointmentSlotID: values.appointmentSlot,
                    description: values.description,
                    paymentProof: values.paymentProof
                };

                dispatch(addAppointment(formattedValues));
            } else {
                const formattedValues = {
                    patientID: values.patient.id,
                    description: values.description
                };

                dispatch(editAppointment(data.id, formattedValues));
            }
        },
        enableReinitialize: 'true'
    });

    useEffect(() => {
        const selectedAppointmentType = appointmentTypeList.find(
            appointmentType =>
                appointmentType.booking_type_name === values.appointmentType
        );
        selectedAppointmentType &&
            dispatch(
                fetchDoctorListByAppointmentType(
                    selectedAppointmentType?.booking_type_name
                )
            );
    }, [appointmentTypeList, values.appointmentType]);

    useEffect(() => {
        if (values.doctor && values.appointmentType && values.date) {
            const selectedAppointmentType = appointmentTypeList.find(
                appointmentType =>
                    appointmentType.booking_type_name === values.appointmentType
            );

            const appointmentTypeName = selectedAppointmentType?.booking_type_name;
            const startDateString = format(
                startOfDay(values.date),
                'yyyy-MM-dd HH:mm:ss'
            );
            const endDateString = format(
                startOfDay(addDays(values.date, 1)),
                'yyyy-MM-dd HH:mm:ss'
            );

            dispatch(
                fetchAppointmentSlotByDoctorAndDateAndAppointmentType(
                    values.doctor.doctor_id,
                    startDateString,
                    endDateString,
                    appointmentTypeName
                )
            );
        }
    }, [values.doctor, values.appointmentType, values.date, appointmentTypeList]);

    const [customerInput, setCustomerInput] = useState('');
    const [patientInput, setPatientInput] = useState('');
    const [doctorInput, setDoctorInput] = useState('');
    const [coDoctorInput, setCoDoctorInput] = useState('');
    const [showSignature, setShowSignature] = useState(false);
    const [signeeName, setSigneeName] = useState('')
    const [signeeRole, setSigneeRole] = useState('')
    let sigCanvas = useRef({});
    const renderTalkWithAnimalsQuestions = () => {
        if (data?.booking_type_name?.includes('RAJANTI TALKS WITH ANIMALS')) {
            // const questions = data?.description ? JSON.parse(data?.description) : [];

            return (
                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: '1rem',
                        paddingLeft: '24px',
                        paddingRight: '24px'
                    }}
                >
                    <Typography variant="h5">
                        Rajanti Talks With Animals Question
                    </Typography>
                    {/* {questions?.map((question, i) => (
            <Typography key={i}>{question || 'NONE'}</Typography>
          ))} */}
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                        {data?.additional_storage || ''}
                    </Typography>
                </Grid>
            );
        }
    };

    const renderCoDoctorSection = () => {
        // console.log(values.coDoctor)
        if (values.coDoctor) {
            return (<Grid item xs={6}>
                <Autocomplete
                    disabled={shouldNotBeEditable}
                    value={values.coDoctor === '' || values.coDoctor === null ? 'N/A' : values.coDoctor}
                    onChange={(_, newValue) => {
                        setCoDoctorInput(newValue);
                    }}
                    getOptionSelected={(option, value) =>
                        option.id === value.id || ''
                    }
                    options={values.coDoctor === null ? [] : values.coDoctor}
                    inputValue={coDoctorInput}
                    onInputChange={(_, newInputValue) => {
                        setCoDoctorInput(newInputValue);
                    }}
                    size="small"
                    renderInput={params => (
                        <TextField
                            {...params}
                            helperText={
                                touched.doctor && errors.doctor ? errors.doctor : ''
                            }
                            error={touched.doctor && errors.doctor ? true : false}
                            label="Co-Doctor *"
                            variant="outlined"
                        />
                    )}
                />
            </Grid>);
        }
    };
    const handleCloseSignature = () => {
        setShowSignature(false)
    }



    const dataURLtoBlob = (dataUrl) => {
        var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    const saveSignature = async () => {
        try {
            let FileModel = new Files();
            if (sigCanvas.current) {
                const dataURL = sigCanvas.current.toDataURL();
                let blob = dataURLtoBlob(dataURL)
                blob = new File([blob], 'signature.png')
                let result = await FileModel.uploadFile(blob)
                if (!result.location) {
                    swal.fire({
                        title: 'Error',
                        text: 'Unable to upload signature, please try again later',
                        icon: 'error',
                        confirmButtonText: 'Go back',
                        customClass: {
                            container: 'swal-z'
                        }
                    });
                    return;

                }
                let obj = {
                    signature_image_url: result.location,
                    signee_name: signeeName,
                    signee_role: signeeRole
                }
                let appointmentModel = new Appointments();
                let updateResult = await appointmentModel.editAppointment(data.id, obj)
                if (updateResult.success) {
                    swal.fire({
                        title: 'success',
                        text: 'successfully saving data',
                        icon: 'success',
                        confirmButtonText: 'Go back',
                        customClass: {
                            container: 'swal-z'
                        }
                    }).then(e => {
                        window.location.reload();
                    });
                    setShowSignature(false)
                }

            }
        } catch (e) {
            swal.fire({
                title: 'Error',
                text: 'Something wrong, please try again later',
                icon: 'error',
                confirmButtonText: 'Go back',
                customClass: {
                    container: 'swal-z'
                }
            });
            return;

            setShowSignature(false)
        }

    }

    const clearSignature = () => {

        sigCanvas.current.clear();
        setSigneeName('')
        setSigneeRole('')
    }

    // console.log("RBASDz", values.doctor, state?.doctor?.listByAppointmentType, "bla")

    console.log("VALUES DOCTOR", values.doctor)

    const shouldNotBeEditable = Boolean(data);
    return (
        <>
            <Dialog aria-labelledby="customized-dialog-title" open={showSignature}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseSignature}>
                    User Signature
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <SignatureCanvas ref={sigCanvas} penColor='black' backgroundColor='#eee'
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
                        <div style={{marginTop: 10}}>
                            <TextField
                                // id="name"
                                value={signeeName}
                                onChange={e => setSigneeName(e.target.value)}
                                label="Nama Tertandatangan"
                                //input={<OutlinedInput label="Tag" />}
                                // helperText={touched.name ? errors.name : ''}
                                // error={touched.name && errors.name ? true : false}
                            />
                        </div>
                        <div style={{marginTop: 10}}>
                            <TextField
                                // id="name"
                                value={signeeRole}
                                onChange={e => setSigneeRole(e.target.value)}
                                label="Peran Tertandatangan"
                                //input={<OutlinedInput label="Tag" />}
                                // helperText={touched.name ? errors.name : ''}
                                // error={touched.name && errors.name ? true : false}
                            />
                        </div>
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={saveSignature} color="primary">
                        Save changes
                    </Button>
                    <Button onClick={clearSignature} color="primary">
                        Reset
                    </Button>
                    <Button onClick={(e) => {
                        setShowSignature(false)
                    }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Card>
                <CardHeader title="Appointment details">{headerToolbar}</CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {
                                !data?.signature_image_url &&
                                <Grid item xs={12}>
                                    <Alert
                                        action={
                                            <Button
                                                onClick={() => {
                                                    setShowSignature(true)
                                                }}
                                                color="inherit" size="small">
                                                Sign
                                            </Button>
                                        }
                                        severity="error">This appointment is not yet signed</Alert>
                                </Grid>
                            }

                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs>
                                        <Autocomplete
                                            disabled={shouldNotBeEditable}
                                            value={values.customer}
                                            onChange={(_, newValue) => {
                                                setFieldValue('customer', newValue);
                                            }}
                                            options={customerList}
                                            getOptionLabel={option =>
                                                option
                                                    ? `${option.user_name} ${role !== 'DOCTOR' ? `(${option.mobile})` : ''
                                                    }`
                                                    : ''
                                            }
                                            inputValue={customerInput}
                                            onInputChange={(_, newInputValue) => {
                                                setCustomerInput(newInputValue);
                                            }}
                                            size="small"
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    helperText={
                                                        touched.customer && errors.customer
                                                            ? errors.customer
                                                            : ''
                                                    }
                                                    error={
                                                        touched.customer && errors.customer ? true : false
                                                    }
                                                    label="Owner *"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {data && (
                                        <Grid item>
                                            <Link to={`/customer/${data.customer_id}`}>
                                                <IconButton size="small" color="primary">
                                                    <LinkIcon />
                                                </IconButton>
                                            </Link>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs>
                                        <Autocomplete
                                            disabled={shouldNotBeEditable}
                                            value={values.patient}
                                            onChange={(event, newValue) => {
                                                setFieldValue('patient', newValue);
                                            }}
                                            options={patientList.filter(
                                                patient =>
                                                    patient.pet_owner_id === values.customer?.id || ''
                                            )}
                                            getOptionLabel={option =>
                                                option ? option.patient_name : ''
                                            }
                                            inputValue={patientInput}
                                            onInputChange={(event, newInputValue) => {
                                                setPatientInput(newInputValue);
                                            }}
                                            size="small"
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    helperText={
                                                        touched.patient && errors.patient
                                                            ? errors.patient
                                                            : ''
                                                    }
                                                    error={touched.patient && errors.patient ? true : false}
                                                    label="Pet Name *"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {data && (
                                        <Grid item>
                                            <Link to={`/patient/${data.patient_id}`}>
                                                <IconButton size="small" color="primary">
                                                    <LinkIcon />
                                                </IconButton>
                                            </Link>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={shouldNotBeEditable}
                                    id="appointmentType"
                                    select
                                    value={values.appointmentType}
                                    onChange={event => {
                                        setFieldValue('appointmentType', event.target.value);
                                    }}
                                    label="Appointment Type *"
                                    helperText={
                                        touched.appointmentType ? errors.appointmentType : ''
                                    }
                                    error={
                                        touched.appointmentType && errors.appointmentType
                                            ? true
                                            : false
                                    }
                                >
                                    {appointmentTypeList.map(appointmentType => (
                                        <MenuItem
                                            key={appointmentType.id}
                                            value={appointmentType.booking_type_name}
                                        >
                                            {`${appointmentType?.booking_type_name
                                                } (${appointmentType?.duration * 10} minutes)`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="expire_at"
                                    disabled
                                    value={values?.doctor ? values?.doctor.doctor_name : ""}
                                    label="Doctor"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="description"
                                    disabled={!isEditing}
                                    value={values.description}
                                    onChange={handleChange}
                                    label="Catatan tambahan"
                                    multiline
                                    rows={8}
                                />
                            </Grid>
                            {!isEditing && <>
                                <Grid item xs={6}>
                                    <TextField
                                        id="created_at"
                                        disabled={!isEditing}
                                        value={moment(data?.created_at).format('DD MMM YYYY, HH:mm:ss')}
                                        onChange={handleChange}
                                        label="Created At"
                                    />
                                </Grid>
                                {
                                    (data?.cancelled_at && data.status === "CANCELLED")
                                    &&
                                    <Grid item xs={6}>
                                        <TextField
                                            id="cancelled_at"
                                            disabled={true}
                                            value={moment(data?.cancelled_at).format('DD MMM YYYY, HH:mm:ss')}
                                            onChange={handleChange}
                                            label="Cancelled At"
                                        />
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <TextField
                                        id="expire_at"
                                        disabled={!isEditing}
                                        value={moment(data?.expire_at).format('DD MMM YYYY, HH:mm:ss')}
                                        onChange={handleChange}
                                        label="Payment Expire At"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="payment_status"
                                        disabled={!isEditing}
                                        value={data?.payment_status}
                                        onChange={handleChange}
                                        label="Payment Status"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="status"
                                        disabled={!isEditing}
                                        value={data?.status}
                                        onChange={handleChange}
                                        label="Status"
                                    />
                                </Grid>
                            </>
                            }
                            {

                                data?.category_id === 3 &&
                                <Grid item xs={6}>
                                    <Autocomplete
                                        disabled={shouldNotBeEditable}
                                        value={values.doctor}
                                        onChange={(_, newValue) => {
                                            setFieldValue('doctor', newValue);
                                        }}
                                        getOptionSelected={(option, value) =>
                                            option.id === value.id || ''
                                        }
                                        options={doctorListByAppointmentType}
                                        getOptionLabel={option => option?.doctor_name || ''}
                                        inputValue={doctorInput}
                                        onInputChange={(_, newInputValue) => {
                                            setDoctorInput(newInputValue);
                                        }}
                                        size="small"
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                helperText={
                                                    touched.doctor && errors.doctor ? errors.doctor : ''
                                                }
                                                error={touched.doctor && errors.doctor ? true : false}
                                                label={"Doctor *"}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                            }

                            {renderCoDoctorSection()}
                            <Grid item xs={12} md={6}>
                                <KeyboardDatePicker
                                    disabled={shouldNotBeEditable}
                                    value={values.date}
                                    onChange={date => setFieldValue('date', date, true)}
                                    label="Appointment Date *"
                                    helperText={touched.date && errors.date ? 'Invalid date' : ''}
                                    error={touched.date && errors.date ? true : false}
                                    clearable
                                    format="dd/MM/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="clinic_name"
                                    disabled={!isEditing}
                                    value={data?.clinic.name}
                                    onChange={handleChange}
                                    label="Clinic"
                                />
                            </Grid>

                            {
                                (data?.category_id === 3) &&
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="operation_table"
                                        disabled={!isEditing}
                                        value={data?.operation_table.name}
                                        onChange={handleChange}
                                        label="Operation Table"
                                    />
                                </Grid>
                            }

                            <Grid item xs={12} md={6}>
                                {!data ? (
                                    <TextField
                                        disabled={shouldNotBeEditable}
                                        id="appointmentSlot"
                                        select
                                        value={values.appointmentSlot}
                                        onChange={event => {
                                            setFieldValue('appointmentSlot', event.target.value);
                                        }}
                                        label="Time *"
                                    >
                                        {appointmentSlotList.map(appointmentSlot => {
                                            const startTimeStr = format(
                                                parseISO(
                                                    appointmentSlot.start_time,
                                                    'yyyy-MM-dd HH:mm:ss'
                                                ),
                                                'HH:mm'
                                            );
                                            const endTimeStr = format(
                                                parseISO(appointmentSlot.end_time, 'yyyy-MM-dd HH:mm:ss'),
                                                'HH:mm'
                                            );

                                            return (
                                                <MenuItem
                                                    key={appointmentSlot.id}
                                                    value={appointmentSlot.id}
                                                >
                                                    {`${startTimeStr} - ${endTimeStr}`}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                ) : (
                                    <TextField
                                        disabled={true}
                                        value={values.appointmentSlot}
                                        label="Time *"
                                    />
                                )}
                            </Grid>
                            {/* {renderTalkWithAnimalsQuestions()} */}

                            {
                                (data?.signature_image_url) &&
                                <Grid
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        paddingLeft: 14
                                    }}
                                    item xs={12} md={12}>
                                    <label>Signature</label>
                                    <img
                                        style={{ height: 80, objectFit: "contain" }}
                                        src={data.signature_image_url} />
                                </Grid>
                            }

                            {
                                (data?.signee_name) &&
                                <Grid
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        paddingLeft: 14
                                    }}
                                    item xs={12} md={2}>
                                    <label>Nama Tertandatangan</label>
                                    {data.signee_name}
                                </Grid>
                            }

                            {
                                (data?.signee_role) &&
                                <Grid
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        paddingLeft: 14
                                    }}
                                    item xs={12} md={2}>
                                    <label>Peran Tertandatangan</label>
                                    {data.signee_role}
                                </Grid>
                            }
                            

                            {!data ? (
                                <Grid item xs={12}>
                                    <input
                                        id="paymentProof"
                                        onChange={event => {
                                            setFieldValue('paymentProof', event.currentTarget.files[0]);
                                        }}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="paymentProof">
                                        <Button color="primary" variant="outlined" component="span">
                                            Upload Payment Proof
                                        </Button>
                                        <span style={{ marginLeft: '1rem' }}>
                                            {values.paymentProof?.name || ''}
                                        </span>
                                    </label>
                                </Grid>
                            ) : (
                                data?.proof_of_payment && (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
                                    >
                                        <hr />
                                        <p>Payment Proof</p>
                                        <img
                                            src={`${data?.proof_of_payment}`}
                                            alt="payment-proof"
                                            style={{ maxWidth: '80%',maxHeight:'300px' }}
                                        />
                                    </Grid>
                                )
                            )}
                            {data ? (
                                <ActionBarEdit
                                    isEditing={isEditing}
                                    isFetching={isLoading}
                                    handleSubmit={handleSubmit}
                                    isDeleteButtonShown={false}
                                />
                            ) : (
                                <ActionBarAdd
                                    isFetching={isLoading}
                                    handleSubmit={handleSubmit}
                                    handleReset={handleReset}
                                />
                            )}
                        </Grid>
                    </form>
                </CardBody>
            </Card>
        </>
    );
};

export default AppointmentForm;
