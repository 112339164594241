import {
  Grid,
  Chip,
} from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TextField } from '../../components/common/TextField';
import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import SymptomModel from '../../models/Symptom';
import DiagnoseModel from '../../models/Diagnose';
import { Row, Col, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DiseaseSymptomSelectionModal from '../disease/DiseaseSymptomSelectionModal';
import DiagnoseSymptomSelectionModal from './DiagnoseSymptomSelectionModal';
import _, { isInteger } from 'lodash';

import mouthCategoryImage from "../../../assets/bodypart/mouth.png"
import eyeCategoryImage from "../../../assets/bodypart/eye.png"
import noseCategoryImage from "../../../assets/bodypart/nose.png"
import stomachCategoryImage from "../../../assets/bodypart/stomach.png"
import furCategoryImage from "../../../assets/bodypart/fur.png"
import legCategoryImage from "../../../assets/bodypart/leg.png"
import addNew from "../../../assets/no_image.jpg"
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import SymptomAddModal from "./SymptomAddModal";
import NewSymptomModal from './NewSymptomModal';

const CATEGORIES = {
  "drinking": "Drinking",
  "eating": "Eating",
  "body_temperature": "Body Temperature",
  "general_condition": "General Condition",
  "food_type": "Food Type",
  "age": "Age",
  "gender": "Gender"
}

const BODY_PARTS = [
  { key: "mouth", label: "Mouth", image: mouthCategoryImage, },
  { key: "eye", label: "Eye", image: eyeCategoryImage, },
  { key: "nose", label: "Nose", image: noseCategoryImage, },
  { key: "stomach", label: "Stomach", image: stomachCategoryImage, },
  { key: "fur", label: "Fur", image: furCategoryImage, },
  { key: "leg", label: "Leg", image: legCategoryImage }
]

const SymptomPage = () => {
  const { id } = useParams();
  const { diagnoseId } = useParams();
  const history = useHistory();

  const [symptomOptions, setSymptomOptions] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [modalData, setModalData] = useState(null);

  const [selectedSymptomImage, setSelectedSymptomImage] = useState({})
  const [showAddModal, setShowAddModal] = useState(false)
  const [showNewSymptomModal, setShowNewSymptomModal] = useState(false)

  const [oldSymptomDiseaseID, setOldSymptomDiseaseID] = useState([]);

  const [symptomDetailToEdit, setSymptomDetailToEdit] = useState(null);

  const getSymptomOptions = async () => {
    let resultSymptom = await SymptomModel.getAllActive();
    let res = resultSymptom.filter(el => el.id < 22 || el.id > 28);
    setSymptomOptions(res);
    //console.log("ini", resultSymptom)
  }

  const initialize = async () => {
    //let record = await DiagnoseModel.getByAppointmentUserandPatientId(id, patientID)
    let record = await DiagnoseModel.getDiagnoseById(diagnoseId)

    //console.log("INI", record)

    if(record) {
      let tempRes = await SymptomModel.getAll();
      let resultSymptom = tempRes.filter(el => el.id < 22 || el.id > 28);
      let oldSymptomDiseases = []
      let uniqueSymptoms = _.keys(
        _.countBy(record.diagnose_record_symptoms.filter(el => el.symptom_id < 22 || el.symptom_id > 28), data => {
          return data.symptom_id;
        })
      );
          
      let symptomsArray = [];
      uniqueSymptoms.forEach(ele => {
        //console.log('LOLOL', ele);
        let filteredSymptomDisease = record.diagnose_record_symptoms.filter(el2 => el2.symptom_id == ele);
        let newSymptom = filteredSymptomDisease[0].symptoms;
        let sd;
        let name;
        let tempDetail = [];
        let unique = [];
        
        filteredSymptomDisease.forEach(element => {
          if (!unique.includes(element.symptom_detail_options.symptoms_detail.detail_name)) {
            unique.push(element.symptom_detail_options.symptoms_detail.detail_name);
          }
        });
        console.log("mencoba", unique)

        unique.forEach(dn => {
          let detail = filteredSymptomDisease
            .filter(el3 => el3.symptom_detail_options.symptoms_detail.detail_name == dn)
            .map((el3, idx) => {
              console.log('WAKACIPAW', el3, filteredSymptomDisease);
              if (!oldSymptomDiseases.includes(el3.id)) {
                oldSymptomDiseases.push(el3.id);
              }
              //if (idx == 0) name = filteredSymptomDisease[idx].symptom_detail_options.symptoms_detail.detail_name;
              let symptom = resultSymptom.filter(res => res.id == el3.symptom_id);
              //console.log(symptom, 'PPPP');
              sd = symptom[0]?.symptoms_detail.map((el, idx) => {
                //console.log('awooga', el);
                let a = {
                  detail_id: el.id,
                  detail_name: el.detail_name,
                  options: el.symptom_detail_options.map(sy => ({
                    symptom_id: symptom[0].id,
                    label: sy.options,
                    symptom_detail_option_id: sy.id,
                    value: sy.id
                  })),
                  type: el.type
                };
                //console.log('apa', a);
                return a;
              });

              return {
                id: el3.id,
                symptom_id: el3.symptom_id,
                label: el3.symptom_detail_options.options,
                symptom_detail_option_id: el3.symptom_detail_option_id,
                value: el3.symptom_detail_option_id
              };
            });

          tempDetail.push({ name: dn, options: [...detail] });
          //tempDetail[dn] = { name: dn, options: [...detail] };
        });

        //console.log(filteredSymptomDisease, 'POPOPOP', newSymptom);
        symptomsArray.push({ ...newSymptom, detail: tempDetail, symptoms_detail: sd });
      });
      //console.log('WKKK', uniqueSymptoms, record.diagnose_record_symptoms, symptomsArray);
      console.log('USMP', record, resultSymptom, symptomsArray);
      setOldSymptomDiseaseID(oldSymptomDiseases)
      setSelectedSymptoms(symptomsArray);
    }
  }

  const diagnose = async () => {
    /* let symArray = [];
    selectedSymptoms?.forEach(el => {
      el.detail?.forEach(det => {
        det.options?.forEach(opt => {
          symArray.push({ symptom_id: opt.symptom_id, symptom_detail_option_id: opt.symptom_detail_option_id });
        });
      });
    }); */
    
    let notDeleted = []
    let symArray = [];
    
    selectedSymptoms?.forEach(el => {
      el.detail?.forEach(det => {
        det.options?.forEach(opt => {
          if (!notDeleted.includes(opt.id) && isInteger(opt.id)) {
            notDeleted.push(opt.id);
          }
          symArray.push({ id: opt.id, symptom_id: opt.symptom_id, symptom_detail_option_id: opt.symptom_detail_option_id });
        });
      });
    });

    let allSymptom

    if(history.location.state?.symptoms) {
      allSymptom = history.location.state?.symptoms
    } else {
      allSymptom = [...symArray, ...history.location.state?.preDiagnose]
    }

    console.log('dikit lagi', selectedSymptoms, allSymptom);
    // if(selectedSymptoms.length > 0) {
      let body = {
        progress_status: "done",
        symptoms: allSymptom
      }

      let record = await DiagnoseModel.getDiagnoseById(diagnoseId)

      let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x));
      let allDeleted
      if(history.location.state?.deleted) {
        allDeleted = [...toBeDeleted, ...history.location.state?.deleted]
      } else {
        allDeleted = []
      }
      console.log("inidia", allDeleted)
      body.deleted = allDeleted
      let result = await DiagnoseModel.edit(record.id, body);

      /* if(record.length > 0) {
        let toBeDeleted = oldSymptomDiseaseID.filter(x => !notDeleted.includes(x));
        body.deleted = toBeDeleted
        result = await DiagnoseModel.edit(record[0].id, body);
      } else if(record.length == 0) {
        result = await DiagnoseModel.create(body)
      } */

      if(result) {
        history.push({
          pathname: "/diagnose/" + id + "/result/" + diagnoseId,
          state: {
            animal_type_id: history.location.state?.animal_type_id,
            generalInfo: {...history.location.state?.generalInfo},
            symptoms: allSymptom
          }
        })
      } else {
        alert("An error occurred")
      }

    // } 


  }

  useEffect(() => {
    getSymptomOptions()
    initialize()
    console.log("mana2", history.location.state)
  }, [])

  return (
      <>
        {
          showAddModal ?  <SymptomAddModal
              open={showAddModal}
              data={selectedSymptomImage}
              options={symptomOptions.filter(obj => obj.body_part == selectedSymptomImage.label).filter(obj => {
                for (let ss of selectedSymptoms) {
                  if (obj.id === ss.id) {
                    return false;
                  }
                }
                return true;
              })}
              onClose={() => {
                setSelectedSymptomImage(null)
                setShowAddModal(false)
              }}
              setModalData={setModalData}
          /> : ''
        }

        {
          showNewSymptomModal ?  <NewSymptomModal
              open={showNewSymptomModal}
              onClose={() => {
                //setSelectedSymptomImage(null)
                setShowNewSymptomModal(false)
                getSymptomOptions()
              }}
          /> : ''
        }

    <div>
      <div style={{ marginTop: "auto", marginBottom: 20 }}>
        <b style={{ fontSize: 20 }}>Add symptoms</b>
        <br />
        Please fill in this form with the symptom found in the patient.
      </div>
      {/* <Card>
        <CardBody> */}
      <Row>
        <Col md={5}>
          <Autocomplete
            id="combo-box-demo"
            // options={symptomOptions ? : []}
            options={
              symptomOptions
                ? symptomOptions?.filter(obj => {
                  for (let ss of selectedSymptoms) {
                    if (obj.id === ss.id) {
                      return false;
                    }
                  }
                  return true;
                })
                : []
            }
            getOptionLabel={option => option.name}
            style={{ width: "100%" }}
            onChange={(event, value) => {
              let sd = value.symptoms_detail.map((el, idx) => {
                //console.log(value);
                let a = {
                  detail_name: el.detail_name,
                  type: el.type,
                  options: el.symptom_detail_options.map(sy => ({
                    symptom_id: value.id,
                    label: sy.options,
                    symptom_detail_option_id: sy.id,
                    value: sy.id
                  }))
                };
                return a;
              });
              value = { ...value, symptoms_detail: sd };
              //console.log(value);
              setModalData(value);
            }}
            value={modalData}
            renderTags={selectedSymptoms => (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: '0rem'
                }}
              >
                {selectedSymptoms.map(el => (
                  <Chip
                    key={el.id}
                    label={el.name}
                    onMouseDown={event => {
                      event.stopPropagation();
                    }}
                    style={{
                      margin: 2,
                      marginTop: 0,
                      marginBottom: 0,
                      borderRadius: '0.25rem'
                    }}
                  />
                ))}
              </div>
            )}
            renderInput={params => (
              <TextField {...params} label="Search symptoms, e.g. vomiting" variant="outlined" />
            )}
          />
          <Grid container style={{ marginTop: 20, display: "inline-flex" }}>
            {selectedSymptoms?.map((el, idx) => {
              return (
                <div 
                  onClick={()=>{
                    setSymptomDetailToEdit(el);
                  }}
                  style={{ background: "#1776cf", borderRadius: 20, padding: 5, color: "white", paddingLeft: 10, display : "flex", flexDirection : "row", alignItems : "center", marginBottom : 5, marginRight : 5, cursor : "pointer" }}
                >
                  {el.name} (
                  {el.detail
                    ?.filter(detailEl => detailEl.options != 'undefined' && detailEl.options != null)
                    .map((detailEl, idx2) => {
                      //console.log(detailEl, 'WOI');
                      return (
                        <>
                          {detailEl.name}:{' '}
                          {detailEl.options?.map((optionEl, idx3) => {
                            if (idx3 < detailEl.options.length - 1) return optionEl.label + ', ';
                            else return optionEl.label;
                          })}
                          {idx2 < el.detail.length - 1 ? ' | ' : ""}
                        </>
                      );
                    })}
                  )
                  <div
                    onClick={e => {
                      let temp = [...selectedSymptoms];
                      temp.splice(idx, 1);
                      setSelectedSymptoms(temp);
                      e.stopPropagation();
                    }}
                    style={{
                      color: 'white',
                      backgroundColor: 'rgba(18, 18, 18, 0.24)',
                      borderRadius: 999999,
                      width: '1rem',
                      height: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginLeft : 5,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M3 9L9 3M3 3L9 9"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              );
            })}
          </Grid>
        </Col>
        <Col md={7}>
          <Row>
            {
              BODY_PARTS.map((bodyPart) => {
                return <Col md={3} style={{ marginBottom: 10, fontWeight: "bold" }}>
                  <div style={{ background: "#1776cf", borderRadius: 10, marginBottom: 3 }}>
                          <img
                              onClick={() => {
                                //console.log('isi body part', bodyPart)
                                setShowAddModal(true)
                                  setSelectedSymptomImage(bodyPart)
                              }}
                              style={{borderRadius: 10, height: "100%", width: "100%"}}
                              src={bodyPart.image}/>
                  </div>
                    {bodyPart.label}
                </Col>
              })
            }
            <Col md={3} style={{ marginBottom: 10, fontWeight: "bold" }}>
                <div style={{ background: "#1776cf", borderRadius: 10, marginBottom: 3 }}>
                        <img
                            onClick={() => {
                              //console.log('isi body part', bodyPart)
                              setShowNewSymptomModal(true)
                                //setSelectedSymptomImage(addNew)
                            }}
                            style={{borderRadius: 10, height: "100%", width: "100%"}}
                            src={addNew}/>
                </div>
                  Add new symptom
              </Col>
          </Row>
        </Col>
      </Row>

      <Grid container style={{ marginBottom: 10 }}>
        <Grid className='d-flex align-items-center justify-content-center' item xs={12}>
          <div
            //onClick={() => { history.push("/diagnose/" + id + "/pre-diagnose/" + patientID) }}
            onClick={() => { history.push({
              pathname: "/diagnose/" + id + "/pre-diagnose/" + diagnoseId,
              state: {
                animal_type_id: history.location.state?.animal_type_id,
                generalInfo: {...history.location.state?.generalInfo},
                preDiagnose: history.location.state?.preDiagnose,
              }
            })}}
            className='d-flex align-items-center justify-content-center'
            style={{ background: "#1776CF", width: 50, height: 50, borderRadius: 5, cursor: "pointer" }}>
            <FaArrowLeft size={20} color='white' />
          </div>
          <div style={{ flex: 1 }}>

          </div>
          <div
            onClick={() => diagnose()}
            className='d-flex align-items-center justify-content-center'
            style={{ background: "#1776CF", height: 50, borderRadius: 50, cursor: "pointer", color :"white", paddingLeft : 20, paddingRight : 20, fontSize : "1.1em", fontWeight : 600 }}>
            Diagnose
            <div className="d-flex align-items-center justify-content-center" style={{background : "#12121230", borderRadius : 20, width : 20, height : 20, marginLeft : 5}}>
              <FaArrowRight size={12} color='white' />
            </div>          
          </div>
        </Grid>
      </Grid>

      <DiagnoseSymptomSelectionModal
        modalData={symptomDetailToEdit}
        onClose={() => setSymptomDetailToEdit(null)}
        onSubmit={tmpSymptom => {
          let currentIndex = selectedSymptoms.findIndex(obj => obj.id === symptomDetailToEdit.id);
          let temp = [...selectedSymptoms];

          let tmp2 = [...tmpSymptom];
          tmp2 = tmp2.map((el, idx) => ({ name: symptomDetailToEdit?.symptoms_detail[idx]?.detail_name, options: el }));

          temp[currentIndex] = { ...temp[currentIndex], detail: tmp2 };
          console.log('UPDATERESULTUPDATE', temp);

          setSelectedSymptoms(temp);
        }}   
      />  

      <DiagnoseSymptomSelectionModal
        modalData={modalData}
        onClose={() => setModalData(null)}
        onSubmit={tmpSymptom => {
          let tmp = selectedSymptoms ? [...selectedSymptoms] : [];
          let tmp2 = [...tmpSymptom];
          tmp2 = tmp2.map((el, idx) => ({ name: modalData?.symptoms_detail[idx]?.detail_name, options: el }));
          ////console.log(tmp2, 'oi');
          tmp.push({ name: modalData.name, detail: tmp2, id: modalData.id, ...modalData });
          ////console.log(tmp, 'oi');
          console.log('UPDATERESULTCREATE', tmp);
          setSelectedSymptoms(tmp);
        }}
      />

    </div>

</>
  );
};



export default SymptomPage;
