import { Badge, Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { useState, useEffect } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function DiseaseResultCard({ disease, show, algoritma }) {
  const [showDisease, setShowDisease] = useState(show);

  return (
    <>
      {/* <Col className='mb-3'>
          <Row className={'mb-3'}> */}
      <Col className={'mx-0 p-0'}>
        {/* <div style={{ textAlign: 'center' }}>
          <h1>Algoritma {algoritma}</h1>
        </div> */}
        <Card>
          <Card.Header style={{ border: 'none' }}>
            <div
              /* className={'d-flex'} */ style={{
                gap: 20,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <div>
                <Typography className={'diagnose-builder-header'}>{disease.name}</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Badge
                  className={
                    algoritma == '1' ? 'dg-primary diagnose-builder-badge' : 'dg-secondary diagnose-builder-badge'
                  }
                  bg="info"
                  pill
                >
                  {disease.possibility}% possibility
                </Badge>
              </div>
              {/* <div
                style={{ fontSize: 20, fontWeight: 800, cursor: 'pointer', userSelect: 'none' }}
                onClick={() => setShowDisease(!showDisease)}
              >
                {showDisease ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div> */}
              <IconButton
              onClick={() => {
                setShowDisease(!showDisease);
              }}
              aria-label="delete"
            >
              {showDisease ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}
            </IconButton>
            </div>
          </Card.Header>
          <Card.Body style={{ padding: '0 2.25rem 2.25rem 2.25rem', display: showDisease ? 'inline' : 'none' }}>
            <Typography className={'diagnose-builder-content'}>
              {disease.description != null ? disease.description : 'No Description'}
            </Typography>
            <Row className={'mt-5'} style={{ padding: '1rem' }}>
              <Typography className={'diagnose-builder-subheader'}>Action</Typography>
            </Row>

            {/* <Row>
                    <Col md={6}> */}
            <ListGroup as="ol" numbered variant="flush">
              <Row>
                {disease.action
                  ?.split('.')
                  ?.filter(v => v != '')
                  .map(v => {
                    return (
                      <Col md={6}>
                        <ListGroup.Item style={{ borderBottom: 'none', border: 'none' }} as="li">
                          <div className={'d-flex'} style={{ gap: 10 }}>
                            <div
                              style={{
                                position: 'relative',
                                top: 5,
                                width: 10,
                                fontSize: 14,
                                height: 10,
                                borderRadius: '100%',
                                background: '#1776CF'
                              }}
                            ></div>
                            <div style={{ flex: 1 }}>{v}</div>
                          </div>
                        </ListGroup.Item>
                      </Col>
                    );
                  })}
              </Row>
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
      {/* </Row>
        </Col> */}
      <br />
    </>
  );
}
