import React, {useEffect, useState} from 'react';

import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls';

import { ListHeaderToolbar } from '../../components/common/HeaderToolbar';
import { DataTableLocal as DataTable } from '../../components/common/DataTable';
import AdminModel from 'model/AdminModel';

const AdminListPage = () => {
  const [admins, setAdmins] = useState([])
  const initialize = async () => {
    try{
      const result = await AdminModel.getAllAdmins()
      setAdmins(result)
    }catch (e) {
      console.log('failed to retrive', e)
    }

  }
  useEffect(() => {
    initialize()
    // dispatch(fetchDiseaseList());
  }, []);
  //
  // const { data } = useSelector(state => state.disease.list);
  // const isFetching = useSelector(state => state.loading.disease);

  const columns = [
    {
      dataField: 'user_name',
      text: 'Username'
    },

  ];

  return (
    <Card>
      <CardHeader title="Admin list">
        <ListHeaderToolbar addLink="/admin/add" addText="New Admin" />
      </CardHeader>
      <CardBody>
        <DataTable
          data={admins}
          totalDataCount={admins.length}
          columns={columns}
          editLink="/admin/"
        />
      </CardBody>
    </Card>
  );
};

export default AdminListPage;
